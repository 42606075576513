import produce from 'immer';
import { atom, Subject } from 'ximple';

type UIState = {
    bookingCheckout: {
        editModalVisible: boolean;
        tabIndex?: number;
    };
    booking: {
        timeslotSelectorVisible: boolean;
        calendarVisible: boolean;
        visible: boolean;
    };
    payment: {
        useCredits: boolean;
    };
    currentUserFlow: 'product' | 'profile';
    valueCardPurchase: {
        calendarVisible: boolean;
        visible: boolean;
    };
};

const initialUiStateAtom: UIState = {
    bookingCheckout: { editModalVisible: false },
    booking: { timeslotSelectorVisible: false, calendarVisible: false, visible: false },
    payment: { useCredits: false },
    currentUserFlow: 'product',
    valueCardPurchase: {
        calendarVisible: false,
        visible: false,
    },
};

type Actions =
    | { type: 'INITIALIZE' }
    | { type: 'OPEN_TIMESLOT_SELECTOR' }
    | {
          type: 'SET_TIMESLOT_SELECTOR_VISIBILITY';
          value: UIState['booking']['timeslotSelectorVisible'];
      }
    | { type: 'OPEN_BOOKING_CALENDAR' }
    | { type: 'SET_BOOKING_CALENDAR_VISIBILITY'; value: UIState['booking']['calendarVisible'] }
    | { type: 'TOGGLE_BOOKING' }
    | { type: 'SET_BOOKING_VISIBILITY'; value: UIState['booking']['visible'] }
    | { type: 'SET_CURRENT_USERFLOW'; value: UIState['currentUserFlow'] }
    | { type: 'OPEN_EDIT_MODAL' }
    | { type: 'CLOSE_EDIT_MODAL' }
    | { type: 'TOGGLE_VALUE_CARD' }
    | { type: 'SET_VALUE_CARD_VISIBILITY'; value: UIState['valueCardPurchase']['visible'] }
    | { type: 'OPEN_VALUE_CARD_CALENDAR' }
    | {
          type: 'SET_VALUE_CARD_CALENDAR_VISIBILITY';
          value: UIState['valueCardPurchase']['calendarVisible'];
      }
    | { type: 'RESET_UI' };

const uiStateReducer = produce((draft: UIState, action: Actions) => {
    switch (action.type) {
        case 'INITIALIZE':
            return {
                ...initialUiStateAtom,
                bookingCheckout: {
                    tabIndex: draft.bookingCheckout.tabIndex,
                    editModalVisible: false,
                },
                booking: { timeslotSelectorVisible: false, calendarVisible: false, visible: false },
                payment: { useCredits: false },
                currentUserFlow: 'product',
            } as UIState;
        case 'OPEN_TIMESLOT_SELECTOR':
            draft.booking.timeslotSelectorVisible = true;
            break;
        case 'SET_TIMESLOT_SELECTOR_VISIBILITY':
            draft.booking.timeslotSelectorVisible = action.value;
            break;
        case 'OPEN_BOOKING_CALENDAR':
            draft.booking.calendarVisible = true;
            break;
        case 'SET_BOOKING_CALENDAR_VISIBILITY':
            draft.booking.calendarVisible = action.value;
            break;
        case 'SET_BOOKING_VISIBILITY':
            draft.booking.visible = action.value;
            break;
        case 'TOGGLE_BOOKING':
            draft.booking.visible = !draft.booking.visible;
            break;
        case 'SET_CURRENT_USERFLOW':
            draft.currentUserFlow = action.value;
            break;
        case 'OPEN_EDIT_MODAL':
            draft.bookingCheckout.editModalVisible = true;
            break;
        case 'CLOSE_EDIT_MODAL':
            draft.bookingCheckout.editModalVisible = false;
            break;
        case 'SET_VALUE_CARD_VISIBILITY':
            draft.valueCardPurchase.visible = action.value;
            break;
        case 'TOGGLE_VALUE_CARD':
            draft.valueCardPurchase.visible = !draft.valueCardPurchase.visible;
            break;
        case 'OPEN_VALUE_CARD_CALENDAR':
            draft.valueCardPurchase.calendarVisible = true;
            break;
        case 'SET_VALUE_CARD_CALENDAR_VISIBILITY':
            draft.valueCardPurchase.calendarVisible = action.value;
            break;
        case 'RESET_UI':
            return initialUiStateAtom as UIState;
    }
});

const uiStateActionLog$ = new Subject<{
    action: Actions;
    state: UIState;
    newState: UIState;
}>();

function updateUiState(state: UIState, action: Actions) {
    const newState = uiStateReducer(state, action);
    uiStateActionLog$.next({ state, newState, action });
    return newState;
}

export const uiStateAtom = atom<UIState, Actions>({
    initialValue: initialUiStateAtom,
    update: updateUiState,
});
