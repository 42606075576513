import { createRoot } from 'react-dom/client';
import { route } from 'src/components/common/router/Router';
import LoginButton from 'src/components/domain/timeslots-authentication/login-button/LoginButton';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import { AuthenticationContextProvider } from '../timeslots/AuthenticationContext';

class BilberryLoginButton extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        // TODO: Use these same as BookingToggler
        // const size = this.getAttribute('size')?.toLowerCase();
        // const xsSize = this.getAttribute('xs-size')?.toLowerCase();
        // const variantText = this.getAttribute('variant')?.toLowerCase();
        // let sizeString: 's' | 'm' | 'l' = 's';
        // let xsSizeOrDefault: 's' | 'm' | 'l' = 's';

        // if (size === 's' || size === 'm' || size === 'l') {
        //     sizeString = size;
        // }

        // if (xsSize === 's' || xsSize === 'm' || xsSize === 'l') {
        //     xsSizeOrDefault = xsSize;
        // }

        const handleOpenProfile = () => {
            route('/my-profile');
        };

        // finally we need to wrap our application in a StylesProvider
        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <AuthenticationContextProvider>
                    <LoginButton
                        sayMySiteWhenLoggedIn
                        useLoggedOutStyling
                        loggedInOnClick={handleOpenProfile}
                    />
                </AuthenticationContextProvider>
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-login-button', BilberryLoginButton);
}
