import CloseIcon from '@mui/icons-material/Close';
import { Alert, AlertTitle, LinearProgress, SxProps, Typography } from '@mui/material';
import { useState } from 'react';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import IconButton from 'src/components/common/timeslots-buttons/icon-button/IconButton';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import { MembershipApiErrorDetails } from '@repo/types';
import { BilberryApiError } from '@repo/widget-utils/services/utils/BilberryApiError';
import { deleteUserProfile } from '@repo/widget-utils/services/fetchers/membership';
import { useAuthenticationContext } from 'src/widgets/timeslots/timeslots/AuthenticationContext';

type ProfileDeletionProps = {
    onBack: () => void;
    sx?: SxProps;
};

export default function ProfileDeletion({ onBack, sx }: ProfileDeletionProps) {
    const { t } = useLocale();
    const { logout } = useAuthenticationContext();
    const [isDeleting, setIsDeleting] = useState(false);
    const [errorDetails, setErrorDetails] = useState<MembershipApiErrorDetails | null>(null);

    const handleDeleteMyProfileClick = async () => {
        try {
            setErrorDetails(null);
            setIsDeleting(true);
            await deleteUserProfile();
            logout(true);
        } catch (error) {
            if (error instanceof BilberryApiError) {
                const errorDetails = await error.errorDetails();
                setErrorDetails(errorDetails);
            } else {
                setErrorDetails({
                    title: 'Error occured when trying to delete user profile',
                    detail: '',
                    status: 500,
                    traceId: '',
                });
            }
        } finally {
            setIsDeleting(false);
        }
    };

    return (
        <Panel>
            <Layout sx={{ ...sx, position: 'relative' }} flexDirection="column">
                <IconButton
                    ariaLabel="close"
                    Icon={CloseIcon}
                    onClick={onBack}
                    color="secondary"
                    sx={{ position: 'absolute', right: 0, top: 0 }}
                />
                <PanelTitle>{t.deleteMyProfile}</PanelTitle>

                <Alert severity="warning">{t.deleteYourProfileInformation}</Alert>

                <Layout
                    flexDirection="column"
                    alignItems="center"
                    justify="space-between"
                    spacing="normal"
                    sx={{ height: '100%' }}
                >
                    <Text>{t.areYouSureYouWantToDeleteYourProfile}</Text>
                    <Layout>
                        <Button
                            text={t.yes}
                            onClick={handleDeleteMyProfileClick}
                            disabled={isDeleting}
                        />
                        <Button text={t.no} onClick={onBack} />
                    </Layout>
                </Layout>

                {isDeleting && <LinearProgress></LinearProgress>}
                {errorDetails && (
                    <Alert severity="error">
                        <AlertTitle>{errorDetails.title}</AlertTitle>
                        {errorDetails.detail && (
                            <Typography variant="body1">{errorDetails.detail}</Typography>
                        )}
                    </Alert>
                )}
            </Layout>
        </Panel>
    );
}
