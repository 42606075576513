import { alpha, Box, useTheme } from '@mui/material';

import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale, formatCurrency } from '@repo/i18n';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { ValueCardPurchase } from 'src/state/valueCard';
import { MembershipValueCardProduct } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple';

export type ValueCardCostSummaryProps = {
    header: string;
    valueCards: MembershipValueCardProduct[];
    valueCardState: ValueCardPurchase;
};
export default function ValueCardCostSummary(props: ValueCardCostSummaryProps): JSX.Element {
    const { header, valueCards } = props;
    const { locale } = useLocale();
    const [currency] = useAtom(currencyAtom);
    const theme = useTheme();
    const customizations = useCustomizations();
    const { membership, credits } = props.valueCardState;

    const currentProduct = () => {
        if (membership.id !== -1) {
            return {
                isMembership: true,
                current: valueCards.find(({ id }) => id === membership.id),
            };
        } else if (credits.id !== -1) {
            return { isMembership: false, current: valueCards.find(({ id }) => id === credits.id) };
        }
        return {};
    };

    const { current } = currentProduct();

    return (
        <Box sx={{ marginTop: theme.spacing(3), fontSize: theme.typography.h4.fontSize }}>
            <Box
                sx={{
                    borderBottom: `1px solid ${alpha(
                        customizations.bookingWidgetColorContrast,
                        0.2,
                    )}`,
                }}
            >
                <PanelTitle>
                    <span
                        style={{
                            color: customizations.bookingWidgetColorContrast,
                            fontWeight: 'bold',
                        }}
                    >
                        {header}
                    </span>
                </PanelTitle>
            </Box>
            <Box sx={{ minHeight: `150px`, marginTop: theme.spacing(1) }}>
                {current && (
                    <Layout flexDirection="column" spacing="dense">
                        <Layout justify="space-between">
                            <Text>
                                <span style={{ color: customizations.bookingWidgetColorContrast }}>
                                    {current.name}
                                    {current.valueCardProductCategory &&
                                        ' - ' + current.valueCardProductCategory.name + ' '}
                                </span>
                            </Text>
                            <Text
                                sx={{
                                    textAlign: 'end',
                                    minWidth: current.campaign ? '90px' : undefined,
                                }}
                            >
                                <span
                                    style={{
                                        color: customizations.bookingWidgetColorContrast,
                                        flexBasis: '25%',
                                        textAlign: 'end',
                                    }}
                                >
                                    {current.campaign && (
                                        <span style={{ whiteSpace: 'nowrap' }}>
                                            <s>
                                                {formatCurrency(
                                                    locale,
                                                    currency,
                                                    current.originalPrice / 100,
                                                )}
                                            </s>
                                        </span>
                                    )}{' '}
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        {formatCurrency(locale, currency, current.price / 100)}
                                    </span>
                                </span>
                            </Text>
                        </Layout>
                    </Layout>
                )}
            </Box>
        </Box>
    );
}
