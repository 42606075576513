import Page from 'src/components/common/page/Page';
import ProfileView from 'src/components/domain/timeslots-views/ProfileView';
import {
    initializeHiddenBodyStyle,
    useHiddenBody as useHiddenBody,
} from 'src/hooks/common/ui/useHiddenBody';

const hiddenBodyStyle = initializeHiddenBodyStyle('timeslots');

export default function Profile() {
    useHiddenBody(hiddenBodyStyle);

    return (
        <Page>
            <ProfileView />
        </Page>
    );
}
