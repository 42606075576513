import { useTheme } from '@mui/material';
import { Iso2Code } from '@repo/types';
import { isEmpty } from 'lodash-es';
import { Fragment, useEffect, useState } from 'react';
import { getDialcodes, norway } from '@repo/common-utils/countries';
import CountrySelect from 'src/components/common/country-select/CountrySelect';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import Checkbox from 'src/components/common/timeslots-fields/checkbox/Checkbox';
import EmailInput from 'src/components/common/timeslots-fields/inputs/email-input/EmailInput';
import PhoneNumberInput from 'src/components/common/timeslots-fields/inputs/phone-number-input/PhoneNumberInput';
import TextInput from 'src/components/common/timeslots-fields/inputs/text-input/TextInput';
import Link from 'src/components/common/timeslots-navigation/link/Link';
import { useLocale } from '@repo/i18n';
import { companyAtom } from 'src/state/company';
import { contactAtom } from 'src/state/contactInfo';
import { useAtomReducer } from 'src/hooks';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple/atoms';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export default function ContactPanel() {
    const { loggedInUser: user } = useMemberContext();

    const theme = useTheme();
    const [{ content, validation }, dispatch] = useAtomReducer(contactAtom);
    const { t } = useLocale();
    const config = useConfigurations();
    const [company] = useAtom(companyAtom);

    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        // Initialize from user if there is a user with data
        if (user && !initialized) {
            dispatch({
                type: 'INITIALIZE',
                value: {
                    firstName: content.firstName || user.firstName,
                    surname: content.surname || user.lastName,
                    address: content.address || user.addressLine1,
                    location: content.location || user.city,
                    phone: content.phone || user.phoneNumber,
                    phonePrefix: !isEmpty(user.phonePrefix)
                        ? user.phonePrefix
                        : getDialcodes()[(config.defaultCountry as Iso2Code) || norway],
                    email: content.email || user.email,
                    country: (user.country || config.defaultCountry || norway) as Iso2Code,
                    zipcode: content.zipcode || user.postalCode,
                    privacyPolicy: content.privacyPolicy,
                    terms: content.terms,
                    receiveNewsletter: content.receiveNewsletter,
                },
            });
            setInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, content, initialized]);

    const updateCountry = (country: Iso2Code) => {
        dispatch({
            type: 'UPDATE_CONTENT',
            value: { country, phonePrefix: getDialcodes()[country] },
        });
    };

    return (
        <form>
            <Panel>
                <Layout flexDirection="column">
                    <TextInput
                        value={content.firstName}
                        onChange={(firstName) =>
                            dispatch({ type: 'UPDATE_CONTENT', value: { firstName } })
                        }
                        label={t.firstName}
                        error={validation.firstName}
                        autoComplete="given-name"
                    />
                    <TextInput
                        value={content.surname}
                        onChange={(surname) =>
                            dispatch({ type: 'UPDATE_CONTENT', value: { surname } })
                        }
                        label={t.lastName}
                        error={validation.surname}
                        autoComplete="family-name"
                    />
                    <TextInput
                        value={content.address}
                        onChange={(address) =>
                            dispatch({ type: 'UPDATE_CONTENT', value: { address } })
                        }
                        label={t.address}
                        error={validation.address}
                        autoComplete="street-address"
                    />
                    <Layout spacing="dense">
                        <TextInput
                            FormGroupProps={{
                                sx: { flex: '0 0 18%', marginRight: theme.spacing(0.5) },
                            }}
                            value={content.zipcode}
                            onChange={(zipcode) =>
                                dispatch({ type: 'UPDATE_CONTENT', value: { zipcode } })
                            }
                            label={t.zipcode}
                            error={validation.zipcode}
                            autoComplete="postal-code"
                        />
                        <TextInput
                            FormGroupProps={{
                                sx: { flex: '1 1 80%' },
                            }}
                            value={content.location}
                            onChange={(location) =>
                                dispatch({ type: 'UPDATE_CONTENT', value: { location } })
                            }
                            label={t.postalLocation}
                            error={validation.location}
                            autoComplete="address-level2"
                        />{' '}
                    </Layout>
                    <CountrySelect
                        country={(content.country ?? config.defaultCountry ?? norway) as Iso2Code}
                        onChange={updateCountry}
                    />
                    <PhoneNumberInput
                        value={content.phone}
                        onChange={(phone) => dispatch({ type: 'UPDATE_CONTENT', value: { phone } })}
                        phonePrefix={content.phonePrefix}
                        onPhonePrefixChange={(phonePrefix) =>
                            dispatch({ type: 'UPDATE_CONTENT', value: { phonePrefix } })
                        }
                        label={t.phoneNumber}
                        errorPrefix={validation.phonePrefix}
                        errorNumber={validation.phone}
                    />
                    <EmailInput
                        value={content.email}
                        onChange={(email) => dispatch({ type: 'UPDATE_CONTENT', value: { email } })}
                        label={t.email}
                        error={validation.email}
                    />

                    <Checkbox
                        checked={content.privacyPolicy}
                        onChange={(privacyPolicy) =>
                            dispatch({ type: 'UPDATE_CONTENT', value: { privacyPolicy } })
                        }
                        error={validation.privacyPolicy}
                        label={
                            <Fragment>
                                {t.iHaveReadAndAccepted}{' '}
                                <Link href={config.privacyUrl} text={t.thePrivacyPolicy} />
                            </Fragment>
                        }
                    />
                    <Checkbox
                        checked={content.terms}
                        onChange={(terms) => dispatch({ type: 'UPDATE_CONTENT', value: { terms } })}
                        error={validation.terms}
                        label={
                            <Fragment>
                                {t.iHaveReadAndAccepted}{' '}
                                <Link
                                    href={config.termsUrl}
                                    text={t.termsOfUse.parsed(company?.company?.name ?? '')}
                                />
                            </Fragment>
                        }
                    />
                    <Checkbox
                        checked={content.receiveNewsletter}
                        onChange={(receiveNewsletter) =>
                            dispatch({ type: 'UPDATE_CONTENT', value: { receiveNewsletter } })
                        }
                        label={t.iWishToRecieveNewsletters}
                    />
                </Layout>
            </Panel>
        </form>
    );
}
