import '@repo/i18n';
import { configureAuth0 } from 'src/state/authentication';
import 'src/utils/widget/error-handling';
import 'src/utils/widget/setup';
import BookTimeslotButtonWidget from 'src/widgets/timeslots/book-timeslot-button/BookTimeslotButton';
import LoginButtonWidget from 'src/widgets/timeslots/login/LoginButtonWidget';
import BilberryTimeslotsWidget from 'src/widgets/timeslots/timeslots/BilberryTimeslotsWidget';
import ValueCardProductCardsWidget from 'src/widgets/timeslots/value-card-product-cards/ValueCardProductCardsWidget';
import ValueCardWidget from 'src/widgets/timeslots/value-card-widget/ValueCardWidget';
import { configureCompany } from './state/company';
import ValueCardProductItemCardsWidget from './widgets/timeslots/value-card-product-item-cards/ValueCardProductItemCardsWidget';
import { getBodyNode } from '@repo/widget-utils/dom-helper';

LoginButtonWidget();
BilberryTimeslotsWidget();
BookTimeslotButtonWidget();
ValueCardProductCardsWidget();
ValueCardProductItemCardsWidget();
ValueCardWidget();

const timeslots = document.createElement('bilberry-timeslots');

// This try / catch is needed because the loading of resources is more volatile when
// deployed in production than when developing, and because the DOMContentLoaded event
// appears to trigger before this code is executed, hence the code inside is not run when in dev.
try {
    const body = getBodyNode();
    body.appendChild(timeslots);
    configureAuth0();
    configureCompany();
} catch (_e) {
    window.addEventListener('DOMContentLoaded', () => {
        const body = getBodyNode();
        body.appendChild(timeslots);
        configureAuth0();
        configureCompany();
    });
}
