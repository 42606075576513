import { SxProps } from '@mui/material';
import { ValueCardPurchase } from 'src/state/valueCard';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useLocale } from '@repo/i18n';
import { Dropdown } from 'src/components/common/dropdown/Dropdown';
import produce from 'immer';
import { MembershipValueCardProduct } from '@repo/types';

type CreditsSelectorProps = {
    onClick?: (e: any) => void;
    visible?: boolean;
    valueCardProducts: MembershipValueCardProduct[];
    valueCardState: ValueCardPurchase;
    variant?: 'contrast' | 'regular';
    setValueCardState: (state: ValueCardPurchase) => void;
    buyNowClicked: boolean;
    sx?: SxProps;
};

export default function ValueCardCreditProductsSelector(props: CreditsSelectorProps) {
    const {
        valueCardProducts,
        valueCardState,
        setValueCardState,
        buyNowClicked,
        variant = 'regular',
        sx,
    } = props;
    const { t } = useLocale();
    const customizations = useCustomizations();

    const dropdownVariant = variant === 'contrast' ? 'booking-card' : 'outlined';
    const dropdownLabelColor =
        variant === 'contrast' ? customizations.bookingWidgetColorContrast : undefined;

    const showValidationError = buyNowClicked && valueCardState.credits.id === -1;

    const findOptionIndex = (id: number) => {
        return valueCardProducts.findIndex((x) => x.id === id);
    };
    const selectedOptionIndex = findOptionIndex(valueCardState.credits.id);
    const selectedValueCardProduct =
        selectedOptionIndex > -1 ? valueCardProducts[selectedOptionIndex] : null;

    const getOptionLabel = (product: MembershipValueCardProduct | null) => {
        if (!product) return t.choose;

        const creditLimit = product.configuration.creditLimit / 100;
        const creditsText = `${creditLimit} ${t.credit.plural}`;

        const nameText = `- ${product.name}`;

        const categoryName = product.valueCardProductCategory?.name ?? '';
        const categoryText = categoryName ? `(${categoryName})` : '';

        const labelParts = [creditsText.trim(), nameText.trim(), categoryText.trim()];
        const label = labelParts.filter((part) => part).join(' ');
        return label;
    };

    const handleDropDownChange = (newValue: MembershipValueCardProduct | null) => {
        const optionLabel = getOptionLabel(newValue);

        setValueCardState(
            produce(valueCardState, (draft: ValueCardPurchase) => {
                draft.credits.name = optionLabel;
                draft.credits.id = newValue ? newValue.id : -1;
            }),
        );
    };

    return (
        <>
            <Dropdown
                variant={dropdownVariant}
                label={t.chooseCreditsType}
                labelColor={dropdownLabelColor}
                options={valueCardProducts}
                getOptionLabel={getOptionLabel}
                color="secondary"
                value={selectedValueCardProduct}
                onChange={handleDropDownChange}
                defaultValue={t.choose}
                error={showValidationError ? t.pleaseSelectTypeOfCreditcard : ''}
                sx={sx}
            />
        </>
    );
}
