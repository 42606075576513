import { Alert, Box, useTheme } from '@mui/material';
import { tzdate, TZDate } from '@repo/tzdate';
import { useState } from 'react';
import { ReactComponent as StopIcon } from 'src/assets/icons/stop.svg';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import ImportantDecisionButton from 'src/components/common/timeslots-buttons/button/ImportantDecisionButton';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import {
    MembershipOrder,
    MembershipUserValueCard,
    OrderStatusEnum,
    PaymentInfo,
} from '@repo/types';
import { ValueCardQRCode } from './ValueCardQRCode';
import LinkButton from 'src/components/common/timeslots-buttons/link-button/LinkButton';
import {
    retrySubscriptionPayment,
    updateValueCardSubscription,
} from '@repo/widget-utils/services/fetchers/membership';
import { useLatestValueCardSubscriptionOrder } from '@repo/widget-utils/services/hooks/membership';
import NetsPaymentWrapper from '../payment/NetsPaymentWrapper';
import { useAtom } from 'ximple';
import { companyAtom } from 'src/state/company';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { findValueCardValidToDisplayDateText } from '@repo/widget-utils/display-helper';
import { getValueCardDateRange } from '@repo/widget-utils/DateHelpers';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

type MembershipInfoValueCardProps = {
    handleCancelMembership: () => void;
    membership: MembershipUserValueCard;
    cancellation?: {
        fromDate: TZDate | null;
        nextChargeAt: TZDate | null;
        cancelledFrom: TZDate | null;
    };
};

export default function MembershipInfoValueCard({
    handleCancelMembership,
    membership,
    cancellation,
}: MembershipInfoValueCardProps) {
    switch (membership.purchaseOrderStatus) {
        case OrderStatusEnum.Completed:
            return (
                <OrderCompletedView
                    handleCancelMembership={handleCancelMembership}
                    membership={membership}
                    cancellation={cancellation}
                ></OrderCompletedView>
            );
        case OrderStatusEnum.AwaitingPayment:
            return <OrderAwaitingPaymentView></OrderAwaitingPaymentView>;
        default:
            return <></>;
    }
}

function OrderCompletedView({
    handleCancelMembership,
    membership,
    cancellation,
}: MembershipInfoValueCardProps) {
    const [updateSubscriptionPaymentInfo, setUpdateSubscriptionPaymentInfo] =
        useState<PaymentInfo | null>(null);

    return (
        <>
            {updateSubscriptionPaymentInfo && (
                <NetsPaymentWrapper
                    paymentInfo={updateSubscriptionPaymentInfo}
                    onPaymentCancelled={() => {
                        setUpdateSubscriptionPaymentInfo(null);
                    }}
                    onPaymentCompleted={() => {
                        setUpdateSubscriptionPaymentInfo(null);
                    }}
                ></NetsPaymentWrapper>
            )}

            {!updateSubscriptionPaymentInfo && (
                <>
                    <OrderCompletedInfo
                        handleCancelMembership={handleCancelMembership}
                        membership={membership}
                        cancellation={cancellation}
                        onUpdateSubscription={setUpdateSubscriptionPaymentInfo}
                    ></OrderCompletedInfo>
                </>
            )}
        </>
    );
}

function OrderAwaitingPaymentView() {
    const { t } = useLocale();
    const { mutateValueCardsForUser, isLoadingValueCardsForUser } = useMemberContext();

    return (
        <>
            <Alert
                severity="info"
                color="info"
                action={
                    <LinkButton
                        onClick={() => mutateValueCardsForUser()}
                        text={`${t.update}`}
                        isLowercase
                        disabled={isLoadingValueCardsForUser}
                    />
                }
            >
                <span>{t.awaiting_payment_info}</span>
            </Alert>
        </>
    );
}

function ValidDateRangePanel(props: { validFrom: string; validTo: string }) {
    const { t } = useLocale();
    const { startDate, endDate } = getValueCardDateRange(props.validFrom, props.validTo);

    return (
        <Panel>
            <Text>
                <Box component="span" style={{ textAlign: 'center' }}>
                    <span>{t.validForThePeriod}</span>
                </Box>
            </Text>
            <Text>
                <span style={{ textAlign: 'center' }}>
                    <b>{`${startDate} - ${endDate}`}</b>
                </span>
            </Text>
        </Panel>
    );
}

function OrderCompletedInfo({
    handleCancelMembership,
    membership,
    cancellation,
    onUpdateSubscription,
}: {
    handleCancelMembership: () => void;
    membership: MembershipUserValueCard;
    cancellation?: {
        fromDate: TZDate | null;
        nextChargeAt: TZDate | null;
        cancelledFrom: TZDate | null;
    };
    onUpdateSubscription: (paymentInfo: PaymentInfo) => void;
}) {
    const { isRestrictedToPurchaseSite } = membership.valueCardProduct.configuration;

    const validToDisplayDate = findValueCardValidToDisplayDateText(membership);

    return (
        <>
            <ValidDateRangePanel
                validFrom={membership.validFrom}
                validTo={validToDisplayDate}
            ></ValidDateRangePanel>

            <ValueCardQRCode
                orderReference={membership.orderReference}
                qrReference={membership.qrReference}
            />

            <PurchaseDateInfo purchaseDate={membership.purchaseDate}></PurchaseDateInfo>

            {isRestrictedToPurchaseSite && (
                <PurchaseSiteInfo purchaseSite={membership.purchaseSite}></PurchaseSiteInfo>
            )}

            <ProductInfo description={membership.valueCardProduct.description}></ProductInfo>

            {cancellation && (
                <SubscriptionInfo
                    valueCardId={membership.id}
                    cancellation={cancellation}
                    onUpdateSubscription={onUpdateSubscription}
                ></SubscriptionInfo>
            )}

            {cancellation && (
                <CancelSubscriptionButton
                    cancellation={cancellation}
                    handleCancelMembership={handleCancelMembership}
                ></CancelSubscriptionButton>
            )}
        </>
    );
}

function PurchaseDateInfo(props: { purchaseDate: string }) {
    const theme = useTheme();

    return (
        <Text variant="small" sx={{ mt: theme.spacing(2), textAlign: 'center' }}>
            {`Kjøpsdato: ${tzdate(props.purchaseDate, false).format('DD/MM/YYYY')}`}
        </Text>
    );
}

function PurchaseSiteInfo(props: { purchaseSite: string }) {
    return (
        <Text variant="small" sx={{ textAlign: 'center' }}>
            {`Kan brukes hos: ${props.purchaseSite}`}
        </Text>
    );
}

function ProductInfo(props: { description: string }) {
    return <Text sx={{ textAlign: 'center' }}>{props.description}</Text>;
}

function SubscriptionInfo(props: {
    valueCardId: number;
    cancellation: {
        fromDate: TZDate | null;
        nextChargeAt: TZDate | null;
        cancelledFrom: TZDate | null;
    };
    onUpdateSubscription: (paymentInfo: PaymentInfo) => void;
}) {
    const { t } = useLocale();
    const [{ currentSite }] = useAtom(companyAtom);
    const config = useConfigurations();
    const {
        order: latestValueCardSubscriptionOrder,
        mutate: mutateLatestValueCardSubscriptionOrder,
    } = useLatestValueCardSubscriptionOrder(props.valueCardId);

    const { fromDate } = props.cancellation;
    const { cancelledFrom } = props.cancellation;
    const { nextChargeAt } = props.cancellation;

    const cannotBeCancelled = fromDate && fromDate.isAfter(Date.now());
    const showNextCharge = nextChargeAt && nextChargeAt.isValid() && cancelledFrom === null;
    const showCancelledFrom = cancelledFrom && cancelledFrom.isValid();

    const handleUpdateSubscriptionPaymentClick = async () => {
        const response = await updateValueCardSubscription(
            props.valueCardId,
            window.location.origin + window.location.pathname,
        );
        props.onUpdateSubscription({
            payment_id: response.paymentId,
            checkout_key: currentSite!.netsCheckoutKey,
            payment_gateway: config.paymentProvider!,
            reference: latestValueCardSubscriptionOrder?.id.toString() ?? '',
        });
    };

    return (
        <>
            <Box style={{ textAlign: 'center' }}>
                {showNextCharge && (
                    <Text variant="small" sx={{ textAlign: 'center' }}>
                        {t.automaticallyRenewed}: {nextChargeAt.format('ll')}
                    </Text>
                )}
                {cannotBeCancelled && (
                    <Text variant="small" sx={{ textAlign: 'center' }}>
                        {t.canBeCancelledFrom}: {fromDate.format('ll') ?? ''}
                    </Text>
                )}
                {showCancelledFrom && (
                    <Text variant="small" sx={{ textAlign: 'center' }}>
                        {t.cancelled}: {cancelledFrom.format('ll')}
                    </Text>
                )}
            </Box>
            {latestValueCardSubscriptionOrder && (
                <Box style={{ textAlign: 'center' }}>
                    <PaymentStatus order={latestValueCardSubscriptionOrder}></PaymentStatus>
                </Box>
            )}

            <Box style={{ textAlign: 'center' }}>
                <LinkButton
                    onClick={handleUpdateSubscriptionPaymentClick}
                    text={t.update_subscription_payment_details}
                    isLowercase
                    rightAligned
                />
            </Box>
            {latestValueCardSubscriptionOrder &&
                latestValueCardSubscriptionOrder.status === OrderStatusEnum.PaymentFailed && (
                    <Box style={{ textAlign: 'center' }}>
                        <RetrySubscriptionPaymentButton
                            valueCardId={props.valueCardId}
                            onSubscriptionPaymentRetried={mutateLatestValueCardSubscriptionOrder}
                        ></RetrySubscriptionPaymentButton>
                    </Box>
                )}
        </>
    );
}

function CancelSubscriptionButton(props: {
    handleCancelMembership: () => void;
    cancellation: {
        fromDate: TZDate | null;
        nextChargeAt: TZDate | null;
        cancelledFrom: TZDate | null;
    };
}) {
    const { t } = useLocale();
    const [areYouSure, setAreYouSure] = useState(false);

    const { fromDate } = props.cancellation;
    const { cancelledFrom } = props.cancellation;

    const cannotBeCancelled = !!fromDate && fromDate.isAfter(Date.now());
    const showButton = cancelledFrom === null && !areYouSure;

    return (
        <>
            {showButton && (
                <ImportantDecisionButton
                    text={t.cancel_membership}
                    Icon={StopIcon as any}
                    disabled={cannotBeCancelled}
                    onClick={() => setAreYouSure(true)}
                />
            )}

            {areYouSure && (
                <Layout
                    flexDirection="column"
                    alignItems="center"
                    justify="space-between"
                    spacing="normal"
                    sx={{ height: '100%' }}
                >
                    <Text>{t.areYouSureYouWantToCancelYourMembership}</Text>
                    <Layout>
                        <Button text={t.yes} onClick={props.handleCancelMembership} />
                        <Button text={t.no} onClick={() => setAreYouSure(false)} />
                    </Layout>
                </Layout>
            )}
        </>
    );
}

function PaymentStatus(props: { order: MembershipOrder }) {
    switch (props.order.status) {
        case OrderStatusEnum.AwaitingPayment:
            return <OrderAwaitingPaymentView></OrderAwaitingPaymentView>;
        case OrderStatusEnum.PaymentFailed:
            return <OrderPaymentFailedView></OrderPaymentFailedView>;
        default:
            return <></>;
    }
}

function OrderPaymentFailedView() {
    const { t } = useLocale();

    return (
        <>
            <Alert severity="error" color="error">
                <span>{t.payment_failed_info}</span>
            </Alert>
        </>
    );
}

function RetrySubscriptionPaymentButton(props: {
    valueCardId: number;
    onSubscriptionPaymentRetried: (order: MembershipOrder) => void;
}) {
    const { t } = useLocale();
    const [isDisabled, setIsDisabled] = useState(false);

    const handleRetryClick = async () => {
        setIsDisabled(true);
        const response = await retrySubscriptionPayment(props.valueCardId);
        props.onSubscriptionPaymentRetried(response);
    };

    return (
        <Button
            text={t.retry_subscription_payment}
            onClick={handleRetryClick}
            disabled={isDisabled}
        />
    );
}
