import { ProductCard2 } from 'src/components/domain/product-card-2/ProductCard2';
import { ProductCardInfo } from 'src/components/domain/product-card-2/ProductCardInfo';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { companyAtom } from 'src/state/company';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import ProductCardListContainer from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListItem';
import { useAtom } from 'ximple';
import { useMemberContext } from '../timeslots/MemberContext';

type ValueCardProductCardsProps = {
    typeIds: number[];
    urls: string[];
    imageUrls: string[];
    numElements: MediaQueryAttributeInput;
    scroll: boolean;
};

export default function ValueCardProductCards({
    typeIds,
    urls,
    imageUrls,
    numElements,
    scroll,
}: ValueCardProductCardsProps) {
    const [{ company }] = useAtom(companyAtom);
    const { loggedInUser, availableValueCardProductsForUser } = useMemberContext();

    const customizations = useCustomizations();

    const valueCardProducts =
        loggedInUser && availableValueCardProductsForUser.length > 0
            ? availableValueCardProductsForUser
            : company?.valueCardProducts;

    return (
        <ProductCardListContainer
            numElements={numElements}
            scroll={scroll}
            productListPadding={customizations.productListPadding}
        >
            {valueCardProducts &&
                company?.valueCardProductTypes
                    .filter(({ id }) =>
                        typeIds.length > 0 ? typeIds.some((typeId) => typeId === id) : true,
                    )
                    .map(({ id, name, description, cardImageUrl, productPageUrl }, i, arr) => {
                        const products = valueCardProducts.filter(
                            (product) => product.productTypeId === id,
                        );

                        const cheapestProduct = products.reduce(
                            (acc, valueCard) => (acc.price < valueCard.price ? acc : valueCard),
                            products[0],
                        );
                        const startingPrice = cheapestProduct.price;

                        return (
                            <ProductCardListItem
                                key={id}
                                numElements={numElements}
                                scroll={scroll}
                                numProductCatalogs={arr.length}
                            >
                                <ProductCard2
                                    key={id}
                                    image={{
                                        url: !imageUrls.length ? cardImageUrl : imageUrls[i],
                                    }}
                                    category={name}
                                    url={!urls.length ? productPageUrl : urls[i]}
                                    body={
                                        <ProductCardInfo
                                            title={name}
                                            description={description}
                                            fromPrice={startingPrice / 100}
                                            url={!urls.length ? productPageUrl : urls[i]}
                                            onClick={() =>
                                                dispatchWidgetEvent({
                                                    eventType: 'viewItem',
                                                    productType: 'ValueCard',
                                                    product: cheapestProduct,
                                                })
                                            }
                                        />
                                    }
                                />
                            </ProductCardListItem>
                        );
                    })}
        </ProductCardListContainer>
    );
}
