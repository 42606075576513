import { TZDate, tzdate } from '@repo/tzdate';
import produce from 'immer';

import StaticCalendar from 'src/components/common/timeslots-calendar/StaticCalendar';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import { useAtomReducer } from 'src/hooks';
import { uiStateAtom } from 'src/state/ui-timeslots';
import { ValueCardPurchase } from 'src/state/valueCard';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';

export default function ValueCardDates({
    valueCardState,
    setValueCardState,
}: {
    valueCardState: ValueCardPurchase;
    setValueCardState: (state: ValueCardPurchase) => void;
}): JSX.Element {
    const [, uiDispatch] = useAtomReducer(uiStateAtom);
    const config = useConfigurations();

    const onChange = (newDate: TZDate | null) => {
        setValueCardState(
            produce(valueCardState, (draft: ValueCardPurchase) => {
                draft.membership.dateTime.date =
                    (newDate as TZDate | null)?.forceLocalTz().toISOString() ?? '';
            }),
        );
        uiDispatch({ type: 'SET_VALUE_CARD_CALENDAR_VISIBILITY', value: false });
    };
    const firstValidDate =
        config.openingDate && tzdate(config.openingDate).isAfter(Date.now())
            ? tzdate(config.openingDate)
            : null;

    return (
        <Layout
            flexDirection="column"
            justify="space-between"
            alignItems="center"
            sx={{ height: '100%' }}
        >
            <StaticCalendar
                date={
                    valueCardState.membership.dateTime.date
                        ? tzdate(valueCardState.membership.dateTime.date)
                        : firstValidDate
                }
                onChange={onChange}
                loading={false}
                minDate={firstValidDate ?? TZDate.now()}
                shouldDisableDate={(date) => {
                    if (!firstValidDate || !date) return false;
                    return tzdate(date).isBefore(firstValidDate.startOf('day'));
                }}
            />
        </Layout>
    );
}
