import { useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import LoggedInPanel from 'src/components/domain/timeslots-authentication/logged-in-panel/LoggedInPanel';
import LoggedOutPanel from 'src/components/domain/timeslots-authentication/logged-out-panel/LoggedOutPanel';
import ValueCardSummaryPanel from 'src/components/domain/timeslots-summary-panel/ValueCardSummaryPanel';
import ValueCardSelectionPanel from 'src/components/domain/timeslots-value-card-selection-panel/ValueCardSelectionPanel';
import { useLocale } from '@repo/i18n';
import { contactAtom } from 'src/state/contactInfo';
import { useAtomReducer } from 'src/hooks';
import { valueCardAtom } from 'src/state/valueCard';
import {
    ValueCardCheckoutTabIndex,
    valueCardCheckoutTabStateAtom,
} from 'src/state/valueCardCheckoutTab';
import { showError } from 'src/utils/widget/error-handling';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export default function ValueCardSelectionView() {
    const { t } = useLocale();
    const { loggedInUser } = useMemberContext();

    const [, valueCardTabDispatch] = useAtomReducer(valueCardCheckoutTabStateAtom);
    const [, contactDispatch] = useAtomReducer(contactAtom);
    const [currentValueCard, valueCardDispatch] = useAtomReducer(valueCardAtom);
    const [valueCardState, setValueCardState] = useState(currentValueCard);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const onCancel = () => {
        contactDispatch({ type: 'RESET' });
        valueCardDispatch({ type: 'CANCEL' });
        window.location.href = window.location.origin + window.location.pathname;
    };

    const handleNext = () => {
        if (
            (valueCardState.category === 'credits' && valueCardState.credits.id < 0) ||
            (valueCardState.category === 'membership' && valueCardState.membership.id < 0)
        ) {
            showError(t.youMustSpecifyAValueCard);
            return;
        } else {
            valueCardDispatch({ type: 'INITIALIZE', value: valueCardState });
            valueCardTabDispatch({
                type: 'GOTO_VALUE_CARD_TAB_BY_INDEX',
                value: ValueCardCheckoutTabIndex.Contact,
            });
        }
    };

    return (
        <Layout
            flexDirection="column"
            sx={{ maxWidth: '100%' }}
            mobileSx={{ width: '100%', boxSizing: 'border-box' }}
        >
            <Layout mobileFlexDirection="column" spacing="snug">
                <Layout
                    flexDirection="column"
                    spacing="snug"
                    sx={{
                        width: '550px',
                        boxSizing: 'border-box',
                        [theme.breakpoints.down('lg')]: {
                            width: '100%',
                        },
                    }}
                >
                    {loggedInUser ? <LoggedInPanel /> : <LoggedOutPanel checkout="valueCard" />}
                    <ValueCardSelectionPanel
                        valueCardState={valueCardState}
                        setValueCardState={setValueCardState}
                    />
                    {!isMobile && (
                        <Layout justify="space-between">
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={onCancel}
                                text={t.cancel}
                            />
                            <Button onClick={handleNext} text={t.next} />
                        </Layout>
                    )}
                </Layout>
                <ValueCardSummaryPanel valueCardState={valueCardState} />
                {isMobile && (
                    <Layout justify="space-between">
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={onCancel}
                            text={t.cancel}
                        />
                        <Button onClick={handleNext} text={t.next} />
                    </Layout>
                )}
            </Layout>
        </Layout>
    );
}
