import { Popover } from '@mui/material';
import React, { useContext } from 'react';
import { MountPointContext } from '@repo/common-utils/mountPoint';

type ValueCardPopoverProps = {
    anchorEl: any;
    children: React.ReactNode[] | React.ReactNode;
    visible?: boolean;
    handleClose: () => void;
    forwardRef?: React.MutableRefObject<HTMLDivElement | null>;
};

export default function ValueCardPopover(props: ValueCardPopoverProps): JSX.Element {
    const { anchorEl, children, handleClose, visible = false, forwardRef } = props;
    const mountPoint = useContext(MountPointContext);

    return (
        <Popover
            ref={forwardRef}
            anchorEl={anchorEl}
            open={visible}
            aria-modal={true}
            anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
            transformOrigin={{ vertical: 'center', horizontal: 'center' }}
            container={mountPoint.popover}
            onClose={handleClose}
        >
            {children}
        </Popover>
    );
}
