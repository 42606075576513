import { Contact } from '@repo/types';
import { validateEmail, validatePhone } from '@repo/common-utils/validation-helper';

export function isContactInfoValid(contact: Contact): [boolean, Contact['validation']] {
    const { content } = contact;
    const invalid = Object.entries(content)
        .filter(([, value]) => value !== undefined && value.toString().match(/^\s*$/))
        .map(([key]) => key);
    const inputValidation = invalid.reduce(
        (acc, invalid) => ({ ...acc, [invalid]: true }),
        {},
    ) as Contact['validation'];

    const isEmailValid = validateEmail(content.email);
    const isPhoneValid = validatePhone({ dialCode: content.phonePrefix, number: content.phone });

    return [
        invalid.length === 0 &&
            content.privacyPolicy &&
            content.terms &&
            isEmailValid &&
            isPhoneValid &&
            content.country !== undefined,
        {
            ...inputValidation,
            phone: !isPhoneValid,
            email: !isEmailValid,
            country: content.country === undefined,
            terms: !content.terms,
            privacyPolicy: !content.privacyPolicy,
        },
    ];
}
