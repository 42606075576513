import {
    capitalize,
    FormControl,
    InputAdornment,
    InputLabel,
    SvgIcon,
    SxProps,
    TextField,
    useTheme,
} from '@mui/material';
import TextFieldStyleUtils from 'src/components/utils/jss/TextFieldStyleUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';

type BookingInputProps = {
    label: string;
    text: string;
    Icon: typeof SvgIcon | null;
    children: React.ReactNode[] | React.ReactNode;
    onClick: (e: any) => void;
    onKeyDown: (e: any) => void;
    ariaLabel: string;
    sx?: SxProps;
    iconSx?: SxProps;
    inputSx?: SxProps;
    variant: 'regular' | 'contrast';
    inputId?: string;
};

export default function BookingInput(props: BookingInputProps) {
    const {
        label,
        text,
        Icon,
        children,
        onClick,
        onKeyDown,
        ariaLabel,
        sx,
        iconSx,
        inputSx,
        variant,
        inputId,
    } = props;
    const theme = useTheme();
    const customizations = useCustomizations();

    const handleKeyboardEvent = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter' || e.key === 'Spacebar' || e.key === ' ') {
            onKeyDown(e);
        }
    };

    return (
        <FormControl fullWidth>
            <InputLabel
                id="how-many-are-going-label"
                sx={{
                    ...TextFieldStyleUtils.positionInputLabelAboveField,
                    whiteSpace: 'nowrap',
                    color:
                        variant === 'contrast'
                            ? customizations.bookingWidgetColorContrast
                            : undefined,
                    ...sx,
                }}
            >
                {capitalize(label)}
            </InputLabel>

            <TextField
                id={inputId}
                sx={{
                    width: '100%',
                    '& .MuiFilledInput-input': {
                        padding: `${theme.spacing(2)} !important`,
                        cursor: 'pointer',
                    },
                    '& .MuiInputBase-root': {
                        cursor: 'pointer',
                    },
                    ...inputSx,
                }}
                onClick={onClick}
                onKeyDown={handleKeyboardEvent}
                variant={variant === 'contrast' ? 'filled' : 'outlined'}
                margin="dense"
                size="small"
                value={text}
                aria-label={ariaLabel}
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            {Icon && <Icon sx={{ ...iconSx }} />}
                        </InputAdornment>
                    ),
                }}
                inputProps={{
                    'aria-labelledby': 'how-many-are-going-label',
                }}
            />
            {children}
        </FormControl>
    );
}
