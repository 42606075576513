import produce from 'immer';
import { getDialcodes, norway } from '@repo/common-utils/countries';
import { atom } from 'ximple';
import { configurationAtom } from '@repo/widget-utils/widgetsConfiguration';
import { Contact, Iso2Code } from '@repo/types';

type Actions =
    | { type: 'INITIALIZE'; value: Contact['content'] }
    | { type: 'RESET' }
    | { type: 'UPDATE_CONTENT'; value: Partial<Contact['content']> }
    | { type: 'UPDATE_VALIDATION'; value: Contact['validation'] };

const initialValue = (): Contact => ({
    content: {
        firstName: '',
        surname: '',
        address: '',
        location: '',
        zipcode: '',
        country: (configurationAtom.subject.value.defaultCountry ?? norway) as Iso2Code,
        phone: '',
        phonePrefix:
            getDialcodes()[(configurationAtom.subject.value.defaultCountry ?? norway) as Iso2Code],
        email: '',
        privacyPolicy: false,
        terms: false,
        receiveNewsletter: false,
    },
    validation: {
        firstName: false,
        surname: false,
        address: false,
        location: false,
        zipcode: false,
        country: false,
        phone: false,
        phonePrefix: false,
        email: false,
        privacyPolicy: false,
        terms: false,
    },
});

const contactReducer = produce((draft: Contact, action: Actions) => {
    switch (action.type) {
        case 'UPDATE_CONTENT':
            draft.content = { ...draft.content, ...action.value };
            break;
        case 'UPDATE_VALIDATION':
            draft.validation = action.value;
            break;
        case 'INITIALIZE':
            draft.content = action.value;
            draft.validation = initialValue()['validation'];
            break;
        case 'RESET':
            return initialValue();
        default:
            break;
    }
});

export const contactAtom = atom<Contact, Actions>({
    initialValue: initialValue(),
    persistKey: 'no.bilberry-timeslots.contact-info',
    update: contactReducer,
});
