import { createRoot } from 'react-dom/client';
import { getMediaQuerySizesFromAttributes } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import ValueCardProductItemCards from './ValueCardProductItemCards';

class MembershipValueCardProductItemCards extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const valueCardProductIds = this.getAttribute('value-card-product-ids')
            ? this.getAttribute('value-card-product-ids')!
                  .toString()
                  .split(',')
                  .map((num) => Number(num))
            : [];
        const valueCardProductUrls = this.getAttribute('value-card-product-urls')
            ? this.getAttribute('value-card-product-urls')!.toString().split(',')
            : [];
        const valueCardProductImageUrls = this.getAttribute('value-card-product-image-urls')
            ? this.getAttribute('value-card-product-image-urls')!.toString().split(',')
            : [];

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 3, 3);
        const scrollAttribute = this.getAttribute('scroll');
        const scroll = scrollAttribute ? scrollAttribute.toString().toLowerCase() === 'yes' : false;

        // finally we need to wrap our application in a StylesProvider
        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ValueCardProductItemCards
                    productIds={valueCardProductIds}
                    imageUrls={valueCardProductImageUrls}
                    urls={valueCardProductUrls}
                    numElements={numElements}
                    scroll={scroll}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define(
        'bilberry-value-card-product-item-cards',
        MembershipValueCardProductItemCards,
    );
}
