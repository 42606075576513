import {
    CalendarPicker,
    CalendarPickerSkeleton,
    LocalizationProvider,
    PickersDay,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { tzdate, TZDate } from '@repo/tzdate';
import { useLocale } from '@repo/i18n';
import { Dayjs } from 'dayjs';

interface IProps {
    date: TZDate | null;
    onChange: (newDate: TZDate | null) => void;
    onMonthChange?: (date: TZDate | null) => void;
    onYearChange?: (date: TZDate | null) => void;
    minDate?: TZDate;
    maxDate?: TZDate;
    shouldDisableDate?: (date: string | null) => boolean;
    loading?: boolean;
    disablePast?: boolean;
}

export default function StaticCalendar(props: IProps): JSX.Element {
    const {
        date,
        onChange,
        onMonthChange,
        onYearChange,
        minDate,
        maxDate,
        shouldDisableDate,
        loading,
        disablePast,
    } = props;

    const { dayjsLocale } = useLocale();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLocale}>
            <CalendarPicker
                autoFocus={true}
                date={date?.forceLocalTz().getDayjsDate() ?? null}
                views={['day']}
                onChange={(d: Dayjs | null) => {
                    const date = d ? tzdate(d) : d;
                    onChange(date);
                }}
                onMonthChange={(d: Dayjs) => {
                    const date = tzdate(d);
                    onMonthChange?.(date);
                }}
                onYearChange={(d: Dayjs) => {
                    const date = tzdate(d);
                    onYearChange?.(date);
                }}
                minDate={minDate?.forceLocalTz().getDayjsDate()}
                maxDate={maxDate?.forceLocalTz().getDayjsDate()}
                renderDay={(
                    _day: Dayjs,
                    _selectedDates: Dayjs[],
                    { key, ...pickersDayProps }: any,
                ) => {
                    return <PickersDay tabIndex={0} key={key} {...(pickersDayProps as any)} />;
                }}
                disableHighlightToday={true}
                shouldDisableDate={(d: Dayjs) => {
                    if (!shouldDisableDate) return false;
                    const res = shouldDisableDate?.(d.format('YYYY-MM-DD'));
                    return res ?? false;
                }}
                loading={loading}
                renderLoading={() => <CalendarPickerSkeleton />}
                disablePast={disablePast}
            />
        </LocalizationProvider>
    );
}
