import { useTheme } from '@mui/material';

import { ReactComponent as CreditsIcon } from 'src/assets/icons/credits.svg';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import RadioPanel from 'src/components/common/timeslots-radio-panel/RadioPanel';
import { ValueCardPurchase } from 'src/state/valueCard';
import { MembershipValueCardProduct } from '@repo/types';
import ValueCardCreditProductsSelector from '../timeslots-value-card/value-card-selectors/ValueCardCreditProductsSelector';

type CreditsPanelProps = {
    title: string;
    credits: MembershipValueCardProduct[];
    isChecked?: boolean;
    onChange: (param?: boolean) => void;
    valueCardState: ValueCardPurchase;
    setValueCardState: (state: ValueCardPurchase) => void;
};

export default function CreditsPanel({
    title,
    credits,
    isChecked,
    onChange,
    valueCardState,
    setValueCardState,
}: CreditsPanelProps) {
    const theme = useTheme();

    return (
        <RadioPanel
            Icon={CreditsIcon as any}
            text={title}
            onChange={!isChecked ? onChange : undefined}
            isChecked={isChecked}
            expandIfChecked={true}
        >
            <Layout flexDirection="column" sx={{ width: '100%', mx: theme.spacing(2) }}>
                <ValueCardCreditProductsSelector
                    valueCardProducts={credits}
                    buyNowClicked={false}
                    valueCardState={valueCardState}
                    setValueCardState={setValueCardState}
                    variant="regular"
                />
            </Layout>
        </RadioPanel>
    );
}
