import { Box, useTheme } from '@mui/material';

import { QRCodeSVG } from 'qrcode.react';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import Dialog from 'src/components/common/timeslots-containers/dialog/Dialog';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';

type QrViewerProps = {
    open: boolean;
    setOpen: (val: boolean) => void;
    orderReference: string | number;
    url: string;
};

export default function QrViewer({ open, setOpen, orderReference, url }: QrViewerProps) {
    const { t } = useLocale();
    const theme = useTheme();

    return (
        <Dialog open={open} onClose={() => setOpen(false)} color="light">
            <PanelTitle variant="h2" sx={{ pb: 4 }}>
                {t.scanTicket}
            </PanelTitle>
            <Box
                sx={{
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'inline-block', width: 'auto' }}>
                    <QRCodeSVG value={url} />
                    <Text variant="small" sx={{ pt: 1 }}>
                        {t.ticketCode}
                    </Text>
                    <Box
                        sx={{
                            py: 1,
                            backgroundColor: theme.palette.grey[50],
                            width: '100%',
                            border: `1px solid ${theme.palette.grey[300]}`,
                            borderRadius: 1,
                        }}
                    >
                        <Text fontWeight="bold" alignment="center">
                            {orderReference}
                        </Text>
                    </Box>
                </Box>
            </Box>
            <Layout sx={{ pt: 4 }} justify="center">
                <Button text={t.close} onClick={() => setOpen(false)} />
            </Layout>
        </Dialog>
    );
}
