import { FormGroup, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { RefObject } from 'react';
import { selectAllInTextInput } from '../inputManipulation';

type TextInputProps = {
    value: string;
    onChange: (param: string) => void;
    required?: boolean;
    label?: string;
    helperText?: string;
    color?: 'primary' | 'secondary';
    helperTextAlignment?: 'start' | 'end';
    selectAllTextOnFocus?: boolean;
    inputRef?: RefObject<HTMLInputElement | undefined>;
    error?: boolean;
    FormGroupProps?: any;
    autoComplete?: HTMLInputElement['autocomplete'];
};

export default function TextInput(props: TextInputProps): JSX.Element {
    const {
        value,
        onChange,
        required = false,
        label,
        helperText,
        color = 'primary',
        helperTextAlignment = 'start',
        selectAllTextOnFocus = false,
        inputRef,
        error,
        FormGroupProps,
        autoComplete,
    } = props;

    const textInputValueChanged = (e: React.ChangeEvent) => {
        if (e.target instanceof HTMLInputElement) onChange(e.target.value);
    };

    return (
        <FormGroup {...FormGroupProps}>
            <InputLabel disableAnimation id="text-input-label" required={required}>
                {label}
            </InputLabel>
            <OutlinedInput
                required={required}
                inputRef={inputRef}
                value={value}
                color={color}
                onChange={textInputValueChanged}
                onFocus={selectAllTextOnFocus ? selectAllInTextInput : undefined}
                type="text"
                size="small"
                name="text"
                inputProps={{ sx: { color: 'secondary.main' }, maxLength: 100 }}
                aria-describedby="text-input-field"
                sx={{
                    width: '100%',
                    'input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 30px white inset !important',
                    },
                }}
                error={error}
                autoComplete={autoComplete}
            />
            <FormHelperText sx={{ ml: helperTextAlignment === 'end' ? 'auto' : undefined }}>
                {helperText}
            </FormHelperText>
        </FormGroup>
    );
}
