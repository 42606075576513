import { createRoot } from 'react-dom/client';
import ValueCard from 'src/components/domain/timeslots-value-card/ValueCard';
import { getYesAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';

class BilberryValueCard extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const cardType = (
            this.hasAttribute('value-card-type')
                ? this.getAttribute('value-card-type')
                : 'membership'
        ) as 'credits' | 'membership';

        const typeId = this.hasAttribute('type-id')
            ? parseInt((this.getAttribute('type-id') ?? -1).toString())
            : -1;

        const expandArrowInside = getYesAttribute(this, 'expand-arrow-inside');
        const positionOffscreen = getYesAttribute(this, 'position-offscreen');

        // finally we need to wrap our application in a StylesProvider
        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ValueCard
                    cardType={cardType}
                    typeId={typeId}
                    expandArrowInside={expandArrowInside}
                    positionOffscreen={positionOffscreen}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-value-card-widget', BilberryValueCard);
}
