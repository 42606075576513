import { useTheme } from '@mui/material';
import { Iso2Code, MembershipUser } from '@repo/types';
import { Fragment, useState } from 'react';
import { getCountries } from '@repo/common-utils/countries';
import LinkButton from 'src/components/common/timeslots-buttons/link-button/LinkButton';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import Informer from 'src/components/common/timeslots-information/informer/Informer';
import Link from 'src/components/common/timeslots-navigation/link/Link';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import AddressEditor from 'src/components/domain/timeslots-editors/address-editor/AddressEditor';
import PersonalInfoEditor from 'src/components/domain/timeslots-editors/personal-info-editor/PersonalInfoEditor';
import ProfileDeletion from 'src/components/domain/timeslots-editors/profile-deletion/ProfileDeletion';
import { useLocale } from '@repo/i18n';
import { showError } from 'src/utils/widget/error-handling';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';

export default function MyProfile(props: {
    user: MembershipUser;
    onUserUpdated: (user: MembershipUser) => void;
}) {
    const { user } = props;
    const { t, locale } = useLocale();
    const config = useConfigurations();

    const [showProfileInfoEditor, setShowProfileInfoEditor] = useState(false);
    const [showAddressEditor, setShowAddressEditor] = useState(false);
    const [showProfileDeletion, setShowProfileDeletion] = useState(false);

    const handleUpdateProfileClicked = () => setShowProfileInfoEditor(true);
    const handleUpdateAddressClicked = () => setShowAddressEditor(true);
    const handleDeleteProfile = () => setShowProfileDeletion(true);
    const theme = useTheme();
    const sx = {
        height: 'fit-content',
        width: '450px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        },
    };

    const editorsAreHidden = !showProfileInfoEditor && !showAddressEditor && !showProfileDeletion;
    return (
        <Fragment>
            <Layout flexDirection="column">
                <PanelTitle
                    sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    variant="h2"
                >
                    {t.myProfile}
                </PanelTitle>
                <Layout
                    flexDirection="column"
                    sx={{ ...sx }}
                    mobileSx={{ width: '100%' }}
                    spacing="small"
                >
                    {editorsAreHidden && (
                        <Panel>
                            <Layout
                                spacing="wide"
                                flexDirection="column"
                                mobileSx={{ width: '100%' }}
                            >
                                <Informer
                                    showLargeTitle
                                    title={
                                        user.firstName !== '' && user.lastName !== ''
                                            ? user.firstName + ' ' + user.lastName
                                            : t.nameNotProvided
                                    }
                                    description={
                                        user.email !== '' ? user.email : t.emailNotProvided
                                    }
                                    description2={
                                        user.phoneNumber !== ''
                                            ? user.phoneNumber
                                            : t.phoneNumberNotProvided
                                    }
                                    updateButtonText={t.updateProfile}
                                    onUpdate={handleUpdateProfileClicked}
                                ></Informer>
                                <Informer
                                    title={t.myAddress}
                                    description={
                                        user.addressLine1 !== ''
                                            ? user.addressLine1
                                            : t.addressNotProvided
                                    }
                                    description2={
                                        user.postalCode !== '' && user.city !== ''
                                            ? user.postalCode +
                                              ' ' +
                                              user.city +
                                              ', ' +
                                              getCountryText(locale, user.country)
                                            : getCountryText(locale, user.country)
                                    }
                                    updateButtonText={t.updateAddress}
                                    onUpdate={handleUpdateAddressClicked}
                                ></Informer>
                                <hr style={{ width: '100%', color: theme.palette.grey['100'] }} />

                                <Text>
                                    <span>{t.readMoreAbout}</span>{' '}
                                    <Link
                                        href={config.privacyUrl}
                                        text={t.privacyPolicy.toLowerCase()}
                                    />
                                </Text>
                            </Layout>
                            <LinkButton
                                text={t.deleteMyProfile}
                                onClick={() => handleDeleteProfile()}
                                isLowercase
                            ></LinkButton>
                        </Panel>
                    )}
                    {showProfileInfoEditor && (
                        <PersonalInfoEditor
                            sx={{ ...sx }}
                            onError={() => showError(t.somethingWentWrongUpdatingUserInfo)}
                            onSave={() => setShowProfileInfoEditor(false)}
                            onBack={() => setShowProfileInfoEditor(false)}
                            user={user}
                            onUpdated={props.onUserUpdated}
                        />
                    )}
                    {showAddressEditor && (
                        <AddressEditor
                            sx={{ ...sx }}
                            onError={() => showError(t.somethingWentWrongUpdatingUserInfo)}
                            onSave={() => setShowAddressEditor(false)}
                            onBack={() => setShowAddressEditor(false)}
                            user={user}
                            onUpdated={props.onUserUpdated}
                        />
                    )}
                    {showProfileDeletion && (
                        <ProfileDeletion onBack={() => setShowProfileDeletion(false)} />
                    )}
                </Layout>
            </Layout>
        </Fragment>
    );
}

function getCountryText(locale: string, country?: string) {
    if (!country) return '';

    const countries = getCountries(locale);
    if (country in countries) {
        const isoCode = country as Iso2Code;
        return countries[isoCode];
    }
    return country;
}
