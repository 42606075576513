import { Button as MuiButton } from '@mui/material';
import { alpha, darken } from '@mui/material/styles';
import { useLocale } from '@repo/i18n';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useAtom } from 'ximple';

export default function ToggleTimeslotButton({
    text,
    size,
    xsSize,
}: {
    text: string;
    size: 's' | 'm' | 'l';
    xsSize: 's' | 'm' | 'l';
}) {
    const { t } = useLocale();
    const customizations = useCustomizations();
    const [toggleBookingState] = useAtom(toggleBookingAtom);

    const handleBookNowClicked = () =>
        toggleBookingAtom.update({ visible: !toggleBookingState.visible, interaction: 'mouse' });
    const handleBookNowEnter = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            toggleBookingAtom.update({
                visible: !toggleBookingState.visible,
                interaction: 'keyboard',
            });
        }
    };

    return (
        <MuiButton
            color="primary"
            variant={customizations.primaryButtonStyle}
            sx={(theme) => ({
                padding: theme.spacing(1, 3),
                color: customizations.bookingButtonTextColor,
                backgroundColor:
                    customizations.bookingButtonStyle === 'contained'
                        ? customizations.bookingButtonColor
                        : 'transparent',
                borderColor:
                    customizations.bookingButtonStyle === 'contained'
                        ? 'transparent'
                        : customizations.bookingButtonTextColor,

                transform: size === 'l' ? 'scale(1.75)' : size === 'm' ? 'scale(1.25)' : 'scale(1)',

                [theme.breakpoints.down('sm')]: {
                    transform:
                        xsSize === 'l'
                            ? 'scale(1.75)'
                            : xsSize === 'm'
                              ? 'scale(1.25)'
                              : 'scale(1)',
                },
                boxShadow:
                    customizations.bookingButtonStyle === 'contained'
                        ? theme.shadows[1]
                        : undefined,
                '&:hover, &:focus, &:active': {
                    boxShadow:
                        customizations.bookingButtonStyle === 'contained'
                            ? theme.shadows[3]
                            : undefined,
                    color: customizations.bookingButtonTextColor,
                    borderColor:
                        customizations.bookingButtonStyle === 'contained'
                            ? 'transparent'
                            : customizations.bookingButtonTextColor,
                    backgroundColor:
                        customizations.bookingButtonStyle === 'contained'
                            ? darken(customizations.bookingButtonColor, 0.2)
                            : alpha(customizations.bookingButtonTextColor, 0.3),
                },
            })}
            onMouseDown={handleBookNowClicked}
            onKeyDown={handleBookNowEnter}
        >
            {text === 'buy' ? t.buyNow : t.bookTimeslot}
        </MuiButton>
    );
}
