import { FormControlLabel, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { PreactCheckbox } from '../../preact-checkbox/PreactCheckbox';

type CheckboxProps = {
    label: JSX.Element | string;
    checked: boolean;
    onChange: (checked: boolean) => void;
    labelPlacement?: 'start' | 'end';
    disabled?: boolean;
    error?: boolean;
};

export default function Checkbox({
    label,
    checked,
    onChange,
    disabled = false,
    labelPlacement = 'end',
    error = false,
}: CheckboxProps) {
    const theme = useTheme();
    const [color, setColor] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (error) setColor(theme.palette.error.main);
        else setColor(theme.palette.text.secondary);
    }, [error, theme.palette.error.main, theme.palette.text.secondary]);

    const handleOnChange = (checked: boolean) => {
        setColor(theme.palette.text.secondary);
        onChange(checked);
    };

    return (
        <FormControlLabel
            label={label}
            labelPlacement={labelPlacement}
            sx={{
                '& > .MuiTypography-root': { color },
            }}
            control={
                <PreactCheckbox
                    checked={checked}
                    disabled={disabled}
                    onChange={handleOnChange}
                    color="secondary"
                />
            }
        ></FormControlLabel>
    );
}
