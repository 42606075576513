import { useTheme } from '@mui/material';

import LinkButton from 'src/components/common/timeslots-buttons/link-button/LinkButton';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';

type InformerProps = {
    title: string;
    showLargeTitle?: boolean;
    description: string;
    description2?: string;
    updateButtonText: string;
    onUpdate: () => void;
};

export default function Informer({
    title,
    showLargeTitle = false,
    description,
    description2,
    updateButtonText,
    onUpdate,
}: InformerProps) {
    const theme = useTheme();

    return (
        <Layout spacing="dense" flexDirection="column" justify="flex-start">
            <Layout spacing="dense" justify="space-between" alignItems="center">
                <PanelTitle
                    sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}
                    variant={showLargeTitle ? 'h3' : 'h4'}
                >
                    <b>{title}</b>
                </PanelTitle>
                <LinkButton onClick={onUpdate} text={updateButtonText} rightAligned isLowercase />
            </Layout>
            {!showLargeTitle && <hr style={{ width: '100%', color: theme.palette.grey['100'] }} />}
            <Text sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                {description}
            </Text>
            {description2 && (
                <Text sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                    {description2}
                </Text>
            )}
        </Layout>
    );
}
