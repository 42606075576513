import { valueCardsByType } from '../traversals/valueCard';
import { MembershipValueCardProduct } from '@repo/types';

export function getSelectedValueCardOrCheapest(
    valueCards: MembershipValueCardProduct[],
    typeId: number,
    selectedProductId?: number,
) {
    const cards = valueCardsByType(valueCards, typeId);
    const card =
        selectedProductId !== undefined
            ? cards.find((x) => x.productTypeId === selectedProductId)
            : cards.reduce((acc: MembershipValueCardProduct | null, cur) => {
                  return acc && acc.price < cur.price ? acc : cur;
              }, null);
    return card ?? null;
}
