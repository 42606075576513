import { IconButton as MuiIconButton, SvgIcon, SxProps } from '@mui/material';

export interface IconButtonProps {
    onClick: () => void;
    ariaLabel: string;
    Icon: typeof SvgIcon;
    onEnter?: () => void;
    disabled?: boolean;
    color?: 'primary' | 'secondary';
    size?: 'small' | 'medium' | 'large';
    sx?: SxProps;
    iconSx?: SxProps;
}

export default function IconButton(props: IconButtonProps): JSX.Element {
    const {
        onClick,
        onEnter,
        ariaLabel,
        Icon,
        disabled = false,
        color = 'primary',
        size = 'medium',
        sx,
        iconSx,
    } = props;
    return (
        <MuiIconButton
            color={color}
            disabled={disabled}
            onClick={onClick}
            onKeyDown={
                onEnter
                    ? (e: React.KeyboardEvent) => {
                          if (e.key === 'Enter') {
                              e.preventDefault();
                              e.stopPropagation();
                              onEnter();
                          }
                      }
                    : undefined
            }
            aria-label={ariaLabel}
            size={size}
            sx={{ ...sx }}
        >
            <Icon sx={iconSx} fontSize={size} />
        </MuiIconButton>
    );
}
