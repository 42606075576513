import { Grid, Typography, useTheme } from '@mui/material';
import { Fragment } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import {
    useLocale,
    formatCurrency,
    getLocaleNumberFormatTwoDecimals,
    formatCurrencyNoDecimalsIfPossible,
} from '@repo/i18n';
import { companyAtom } from 'src/state/company';
import { currencyAtom } from '@repo/widget-utils/currencyAtom';
import { ValueCardPurchase } from 'src/state/valueCard';
import { getTotalPrice, getTotalPriceWithReductions } from '@repo/widget-utils/value-cards/price';
import { configurationAtom, useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { useAtom } from 'ximple';
import Separator from './common/Separator';
import SummaryApplyGiftCardPayments from '../summary/SummaryApplyGiftCardPayments';
import {
    calculateAppliedGiftcards,
    getAppliedPromoCodeIfApplicableForValueCardPurchase,
} from '@repo/widget-utils/price-helper';
import {
    BilberryGiftcardStatus,
    BilberryPromoCodeStatus,
    AppliedGiftCard,
    MembershipValueCardProduct,
} from '@repo/types';
import { capitalize, sumBy, uniqBy } from 'lodash-es';
import { showError } from 'src/utils/widget/error-handling';
import SummaryPromoCode from '../summary/SummaryPromoCode';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useCartContext } from 'src/widgets/CartContext';

export default function ValueCardSummaryPanel({
    valueCardState,
    isOnPaymentView,
}: {
    valueCardState: ValueCardPurchase;
    isOnPaymentView?: boolean;
}) {
    const { t } = useLocale();
    const theme = useTheme();
    const configurations = useConfigurations();
    const { loggedInUser, availableValueCardProductsForUser } = useMemberContext();

    const isGiftcardEnabled = !!configurations.enableGiftcards;
    const isPromoCodeEnabled = !!configurations.enablePromoCodes;
    const { category, membership, credits } = valueCardState;
    const [{ company }] = useAtom(companyAtom);

    const { valueCardProductTypes } = company ?? {};
    const currentId = category === 'membership' ? membership.id : credits.id;

    const valueCardProducts =
        loggedInUser && availableValueCardProductsForUser.length > 0
            ? availableValueCardProductsForUser
            : company?.valueCardProducts;

    const { giftcards, setGiftcards, appliedPromoCode, setAppliedPromoCode } = useCartContext();

    const valueCard = valueCardProducts?.find(({ id }) => id === currentId);
    const valueCardType = valueCardProductTypes?.find(({ id }) => id === valueCard?.productTypeId);

    const applicableAndAppliedPromoCode = getAppliedPromoCodeIfApplicableForValueCardPurchase(
        appliedPromoCode,
        currentId,
    );

    const discounts = applicableAndAppliedPromoCode ? [applicableAndAppliedPromoCode.discount] : [];
    const totalPrice = getTotalPrice(valueCard);
    const totalPriceWithReductions = getTotalPriceWithReductions(valueCard, discounts);
    const totalPromoCodeDiscount = totalPrice - totalPriceWithReductions;

    const appliedGiftcards = calculateAppliedGiftcards(giftcards, totalPriceWithReductions);
    const totalGiftcardDiscount = sumBy(appliedGiftcards, (x) => x.priceReduction);

    const totalPriceAfterAppliedGiftcards = totalPriceWithReductions - totalGiftcardDiscount;

    const handleApplyGiftcard = (giftcard: BilberryGiftcardStatus) => {
        const oldGiftcardStatuses = appliedGiftcards.map((x) => x.giftcardStatus);
        const newGiftcardStatuses = uniqBy([...oldGiftcardStatuses, giftcard], (x) => x.id);

        setGiftcards(newGiftcardStatuses);
    };

    const handleRemoveAppliedGiftcard = (appliedGiftcard: AppliedGiftCard) => {
        const newGiftcardStatuses = appliedGiftcards
            .filter((x) => x !== appliedGiftcard)
            .map((x) => x.giftcardStatus);

        setGiftcards(newGiftcardStatuses);
    };

    return (
        <Panel
            variant="shaded"
            sx={{
                height: 'fit-content',
                width: '450px',
                minWidth: '450px',
                position: 'sticky',
                boxSizing: 'border-box',
                top: theme.spacing(2),
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    minWidth: 0,
                },
            }}
        >
            <Layout flexDirection="column">
                <PanelTitle
                    variant="h2"
                    sx={{ paddingTop: theme.spacing(2), paddingBottom: theme.spacing(2) }}
                >
                    {t.summary}
                </PanelTitle>

                {valueCard ? (
                    <Fragment>
                        <ValueCardInfo valueCard={valueCard} title={valueCardType?.name ?? ''} />
                        <VatPrices valueCard={valueCard} />
                    </Fragment>
                ) : (
                    ''
                )}

                {isOnPaymentView && appliedGiftcards.length > 0 && (
                    <GiftcardReductionAmount
                        totalGiftcardDiscount={totalGiftcardDiscount}
                    ></GiftcardReductionAmount>
                )}

                {isPromoCodeEnabled && (
                    <SummaryPromoCode
                        onPromoCodeApplied={setAppliedPromoCode}
                        appliedPromoCode={appliedPromoCode}
                        currentPriceReduction={totalPromoCodeDiscount}
                        writeable={!isOnPaymentView}
                    />
                )}

                {isGiftcardEnabled && !isOnPaymentView && (
                    <SummaryApplyGiftCardPayments
                        appliedGiftcards={appliedGiftcards}
                        onApplyGiftcard={handleApplyGiftcard}
                        onRemoveAppliedGiftcard={handleRemoveAppliedGiftcard}
                        onError={showError}
                    />
                )}

                <TotalPrice price={totalPriceAfterAppliedGiftcards} />
            </Layout>
        </Panel>
    );
}

type ValueCardInfoProps = {
    valueCard: MembershipValueCardProduct;
    title: string;
};

function ValueCardInfo({ valueCard, title }: ValueCardInfoProps) {
    const [company] = useAtom(companyAtom);
    const [currency] = useAtom(currencyAtom);
    const { locale } = useLocale();

    return (
        <Layout flexDirection="column" spacing="dense">
            <Layout flexDirection="column" spacing="dense">
                <Text fontWeight="bold">
                    {company.currentSite?.name.toUpperCase()} - {title}{' '}
                    {valueCard.campaign && '(' + valueCard.campaign.name + ')'}
                </Text>
            </Layout>
            <Separator />
            <Layout flexDirection="row" justify="space-between" sx={{ paddingBottom: '50px' }}>
                <Text>
                    {valueCard.name}
                    {valueCard.valueCardProductCategory &&
                        ' - ' + valueCard.valueCardProductCategory.name + ' '}
                </Text>

                {valueCard.campaign ? (
                    <Text sx={{ textAlign: 'end' }}>
                        (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            <s>
                                {formatCurrency(
                                    locale,
                                    currency,
                                    valueCard.originalPrice / 100,
                                    2,
                                    2,
                                )}
                            </s>
                        </span>
                        )&nbsp;
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {formatCurrency(locale, currency, valueCard.price / 100, 2, 2)}
                        </span>
                    </Text>
                ) : (
                    <Text>{getLocaleNumberFormatTwoDecimals(locale, valueCard.price / 100)}</Text>
                )}
            </Layout>
        </Layout>
    );
}

type VatPricesProps = {
    valueCard: MembershipValueCardProduct;
};

function VatPrices({ valueCard }: VatPricesProps) {
    const { t, locale } = useLocale();
    const [configuration] = useAtom(configurationAtom);

    const netPrice = valueCard.netPrice / 100;
    const rate = valueCard.taxRate / 100;
    const vatAmount = valueCard.taxAmount / 100;

    if (configuration.hideVat) return null;

    return (
        <Fragment>
            <Separator />
            <Layout flexDirection="column" alignItems="flex-end" spacing="dense">
                <Grid container spacing={1}>
                    <Grid item xs={9}>
                        <Text sx={{ textAlign: 'right' }}>{t.withoutVat}</Text>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: 'right' }}>
                        <span>{getLocaleNumberFormatTwoDecimals(locale, netPrice)}</span>
                    </Grid>
                    <Grid item xs={9}>
                        <Text sx={{ textAlign: 'right' }}>
                            {t.vat} {rate}%
                        </Text>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: 'right' }}>
                        <span>{getLocaleNumberFormatTwoDecimals(locale, vatAmount)}</span>
                    </Grid>
                </Grid>
            </Layout>
            <Separator />
        </Fragment>
    );
}
type TotalPriceProps = {
    price: number;
};

function TotalPrice({ price }: TotalPriceProps) {
    const theme = useTheme();
    const { t, locale } = useLocale();
    const [currency] = useAtom(currencyAtom);

    return (
        <Layout flexDirection="column" alignItems="flex-end">
            <Text fontWeight="bold">
                {t.total.toUpperCase()}&nbsp;
                <span
                    style={{
                        marginLeft: theme.spacing(1),
                        fontSize: 22,
                    }}
                >
                    {formatCurrencyNoDecimalsIfPossible(locale, currency, price)}
                </span>
            </Text>
        </Layout>
    );
}

function GiftcardReductionAmount(props: { totalGiftcardDiscount: number }) {
    const { t, locale } = useLocale();

    return (
        <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex="3"
                flexShrink="1"
            >
                <Typography color="secondary" align="right">
                    {capitalize(t.gift_card)}
                </Typography>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex="1"
                flexGrow="1"
            >
                <Typography color="secondary" align="right">
                    -{getLocaleNumberFormatTwoDecimals(locale, props.totalGiftcardDiscount)}
                </Typography>
            </Grid>
        </Grid>
    );
}

function PromoCodeReductionAmount(props: {
    totalPromoCodeDiscount: number;
    appliedPromoCode: BilberryPromoCodeStatus;
}) {
    const { locale } = useLocale();

    return (
        <Grid container direction="row" justifyContent="space-between" wrap="nowrap">
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex="3"
                flexShrink="1"
            >
                <Typography color="secondary" align="right">
                    {props.appliedPromoCode.coupon_code}
                </Typography>
            </Grid>

            <Grid
                container
                direction="column"
                justifyContent="space-between"
                wrap="nowrap"
                flex="1"
                flexGrow="1"
            >
                <Typography color="secondary" align="right">
                    -{getLocaleNumberFormatTwoDecimals(locale, props.totalPromoCodeDiscount)}
                </Typography>
            </Grid>
        </Grid>
    );
}
