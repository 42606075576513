import { Box, useTheme } from '@mui/material';
import { arc, pie } from 'd3-shape';

import { useEffect, useState } from 'react';

type AmountChartProps = {
    amount: number;
    max: number;
    width: number;
    height: number;
};

export default function AmountChart({ amount, max, width, height }: AmountChartProps) {
    const [arcs, setArcs] = useState<(string | null)[]>([]);
    const theme = useTheme();

    useEffect(() => {
        const pieGen = pie();
        const arcGen = arc()
            .outerRadius(width / 2)
            .innerRadius(width / 2 - 5);

        const data = [...Array(max)].fill(1);
        const angles = pieGen(data);

        const arcs = angles.map((d: any) => arcGen(d));
        setArcs(arcs);
    }, [max, width]);

    return (
        <Box sx={{ position: 'absolute' }}>
            <svg width={width} height={height}>
                <g transform={`translate(${width / 2},${height / 2})`}>
                    {arcs.map((arc: string | null, i: number) => (
                        <path
                            key={arc}
                            d={arc ?? undefined}
                            fill={amount > i ? '#65D900' : theme.palette.grey[200]}
                        />
                    ))}
                </g>
            </svg>
        </Box>
    );
}
