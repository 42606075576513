import TodayIcon from '@mui/icons-material/Today';
import { FormHelperText } from '@mui/material';

import { useLocale } from '@repo/i18n';
import { getSelectedDateText, ValueCardPurchase } from 'src/state/valueCard';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import BookingInput from '../../timeslots-booking/booking-selectors/booking-input/BookingInput';
import ValueCardCalendar from '../calendar/value-card-calendar/ValueCardCalendar';

const valueCardCalendarInputId = 'value-card-calendar-input';

type MembershipDateSelectorProps = {
    onClick: (param: any) => void;
    onKeyDown: (param: any) => void;
    anchorEl: any;
    valueCardState: ValueCardPurchase;
    setValueCardState: (state: ValueCardPurchase) => void;
    hideCalendar?: boolean;
    buyNowClicked: boolean;
    variant?: 'contrast' | 'regular';
};

export default function MembershipDateSelector(props: MembershipDateSelectorProps) {
    const {
        onClick,
        onKeyDown,
        anchorEl,
        valueCardState,
        setValueCardState,
        hideCalendar = false,
        buyNowClicked,
        variant = 'regular',
    } = props;
    const { t } = useLocale();
    const { membership } = valueCardState;
    const showValidationError = buyNowClicked && !membership.dateTime.date;
    const selectedDateTimeText = getSelectedDateText(membership.dateTime);
    const customizations = useCustomizations();

    return (
        <BookingInput
            onClick={onClick}
            onKeyDown={onKeyDown}
            text={selectedDateTimeText !== ' ' ? selectedDateTimeText : t.choose}
            label={t.chooseStartDate}
            Icon={TodayIcon}
            ariaLabel={'calendar input'}
            variant={variant}
            inputId={valueCardCalendarInputId}
        >
            {!hideCalendar && (
                <ValueCardCalendar
                    anchorEl={anchorEl}
                    valueCardState={valueCardState}
                    setValueCardState={setValueCardState}
                    inputId={valueCardCalendarInputId}
                />
            )}
            {showValidationError && (
                <FormHelperText sx={{ color: customizations.bookingWidgetPrimaryColor }}>
                    {t.pleaseSelectAStartDate}
                </FormHelperText>
            )}
        </BookingInput>
    );
}
