import { useEffect, useState } from 'react';
import { ReactComponent as MembershipIcon } from 'src/assets/icons/membership.svg';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import RadioPanel from 'src/components/common/timeslots-radio-panel/RadioPanel';
import MembershipDateSelector from 'src/components/domain/timeslots-value-card/value-card-selectors/MembershipDateSelector';
import MembershipTypeSelector from 'src/components/domain/timeslots-value-card/value-card-selectors/MembershipTypeSelector';
import { useAtomReducer } from 'src/hooks';
import { uiStateAtom } from 'src/state/ui-timeslots';
import { ValueCardPurchase } from 'src/state/valueCard';
import { MembershipValueCardProduct } from '@repo/types';

type MembershipPanelProps = {
    title: string;
    memberships: MembershipValueCardProduct[];
    isChecked?: boolean;
    onChange: (param?: boolean) => void;
    valueCardState: ValueCardPurchase;
    setValueCardState: (state: ValueCardPurchase) => void;
};

export default function MembershipPanel({
    title,
    memberships,
    isChecked,
    onChange,
    valueCardState,
    setValueCardState,
}: MembershipPanelProps) {
    const [{ valueCardPurchase }, uiDispatch] = useAtomReducer(uiStateAtom);
    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLInputElement | null>(null);

    useEffect(() => {
        setAnchorEl(null);
    }, [isChecked]);

    const selectedProduct = memberships.find((x) => x.id === valueCardState.membership.id);
    const showStartDateSelector = selectedProduct?.configuration?.validForDurationInDays;

    return (
        <RadioPanel
            Icon={MembershipIcon as any}
            text={title}
            onChange={!isChecked ? onChange : undefined}
            isChecked={isChecked}
            expandIfChecked={true}
        >
            <Layout flexDirection="column" sx={{ width: '100%' }}>
                <MembershipTypeSelector
                    buyNowClicked={false}
                    memberships={memberships}
                    valueCardState={valueCardState}
                    setValueCardState={setValueCardState}
                    variant="regular"
                />
                {showStartDateSelector && (
                    <MembershipDateSelector
                        buyNowClicked={false}
                        onClick={(e) => {
                            uiDispatch({
                                type: 'SET_VALUE_CARD_CALENDAR_VISIBILITY',
                                value: !valueCardPurchase.calendarVisible,
                            });
                            setAnchorEl(e.currentTarget);
                        }}
                        onKeyDown={(e) => {
                            uiDispatch({
                                type: 'SET_VALUE_CARD_CALENDAR_VISIBILITY',
                                value: !valueCardPurchase.calendarVisible,
                            });
                            setAnchorEl(e.currentTarget);
                        }}
                        hideCalendar={!isChecked}
                        anchorEl={anchorEl}
                        valueCardState={valueCardState}
                        setValueCardState={setValueCardState}
                        variant="regular"
                    />
                )}
            </Layout>
        </RadioPanel>
    );
}
