import {
    Box,
    FormGroup,
    FormHelperText,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField,
} from '@mui/material';
import { RefObject } from 'react';
import { selectAllInTextInput } from '../inputManipulation';

type PhoneNumberInputProps = {
    value: string;
    onChange: (param: string) => void;
    phonePrefix: string;
    onPhonePrefixChange: (param: string) => void;
    required?: boolean;
    label?: string;
    helperText?: string;
    color?: 'primary' | 'secondary';
    helperTextAlignment?: 'start' | 'end';
    selectAllTextOnFocus?: boolean;
    inputRef?: RefObject<HTMLInputElement | undefined>;
    errorPrefix?: boolean;
    errorNumber?: boolean;
};

export default function PhoneNumberInput(props: PhoneNumberInputProps): JSX.Element {
    const {
        value,
        onChange,
        phonePrefix,
        onPhonePrefixChange,
        required = false,
        label,
        helperText,
        color = 'primary',
        helperTextAlignment = 'start',
        selectAllTextOnFocus = false,
        inputRef,
        errorPrefix,
        errorNumber,
    } = props;

    const phoneNumberInputValueChanged = (e: React.ChangeEvent) => {
        if (e.target instanceof HTMLInputElement) {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
            onChange(e.target.value);
        }
    };
    const phoneNumberPrefixValueChanged = (e: React.ChangeEvent) => {
        if (e.target instanceof HTMLInputElement) {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
            onPhonePrefixChange(e.target.value);
        }
    };

    return (
        <FormGroup>
            <InputLabel disableAnimation id="phone-number-input-label" required={required}>
                {label}
            </InputLabel>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <TextField
                    sx={(theme) => ({
                        flex: '0 0 18%',
                        marginRight: theme.spacing(0.5),
                        marginTop: 0,
                        minWidth: '90px',
                    })}
                    error={errorPrefix}
                    required={required}
                    value={phonePrefix}
                    onChange={phoneNumberPrefixValueChanged}
                    variant="outlined"
                    type={'text'}
                    size="small"
                    name="phonePhonePrefix"
                    autoComplete="tel-country-code"
                    inputProps={{
                        sx: {
                            color: 'secondary.main',
                            paddingLeft: '0px !important',
                        },
                        maxLength: 100,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                    }}
                    InputProps={{
                        startAdornment: <InputAdornment position="start">+</InputAdornment>,
                    }}
                />

                <Box sx={{ flex: '1 1 65%' }}>
                    <OutlinedInput
                        required={required}
                        inputRef={inputRef}
                        value={value}
                        color={color}
                        onChange={phoneNumberInputValueChanged}
                        onFocus={selectAllTextOnFocus ? selectAllInTextInput : undefined}
                        type="tel"
                        inputProps={{
                            inputMode: 'numeric',
                            pattern: '[0-9]*',
                            maxLength: 100,
                            sx: { color: 'secondary.main' },
                        }}
                        size="small"
                        name="phone number"
                        aria-describedby="phone-number-input-field"
                        sx={{
                            width: '100%',
                            'input:-webkit-autofill': {
                                WebkitBoxShadow: '0 0 0 30px white inset !important',
                            },
                        }}
                        error={errorNumber}
                        autoComplete="tel-national"
                    />
                    <FormHelperText sx={{ ml: helperTextAlignment === 'end' ? 'auto' : undefined }}>
                        {helperText}
                    </FormHelperText>
                </Box>
            </Box>
        </FormGroup>
    );
}
