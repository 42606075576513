import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, SxProps, useTheme } from '@mui/material';
import { MembershipUser } from '@repo/types';
import { Fragment, useState } from 'react';
import CountrySelect from 'src/components/common/country-select/CountrySelect';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import IconButton from 'src/components/common/timeslots-buttons/icon-button/IconButton';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import TextInput from 'src/components/common/timeslots-fields/inputs/text-input/TextInput';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import { useLocale } from '@repo/i18n';
import { putUser } from '@repo/widget-utils/services/fetchers/membership';
import { getUserCountry } from '@repo/common-utils/countries';

type AddressEditorProps = {
    user: MembershipUser;
    onUpdated: (user: MembershipUser) => void;
    onSave: () => void;
    onBack: () => void;
    onError: (param: boolean) => void;
    sx?: SxProps;
};

export default function AddressEditor({
    user,
    onUpdated,
    onSave,
    onError,
    onBack,
    sx,
}: AddressEditorProps) {
    const { t, locale } = useLocale();
    const theme = useTheme();

    const [specificAddress, setSpecificAddress] = useState(user.addressLine1);
    const [zipcode, setZipcode] = useState(user.postalCode);
    const [location, setLocation] = useState(user.city);
    const [country, setCountry] = useState(user.country);

    const countryIso2Code = getUserCountry(locale, user?.country ?? '');

    const [spinner, setSpinner] = useState(false);
    async function handleSave(): Promise<void> {
        setSpinner(true);

        try {
            const userUpdateRequest: MembershipUser = {
                ...user,
                addressLine1: specificAddress,
                postalCode: zipcode,
                city: location,
                country: country,
            };

            const updatedUser = await putUser(userUpdateRequest);
            onUpdated(updatedUser);
        } catch (error) {
            onError(true);
        }
        onSave();
        setSpinner(false);
    }

    return (
        <Fragment>
            <Panel sx={sx}>
                <Layout sx={{ position: 'relative' }} flexDirection="column">
                    <IconButton
                        ariaLabel="close"
                        Icon={CloseIcon}
                        onClick={onBack}
                        sx={{ position: 'absolute', right: 0, top: 0 }}
                    />
                    <PanelTitle>Oppdater adresse</PanelTitle>
                    <TextInput
                        value={specificAddress}
                        onChange={(value) => setSpecificAddress(value)}
                        label="Adresse"
                    />
                    <Layout spacing="dense">
                        <TextInput
                            FormGroupProps={{
                                sx: { flex: '0 0 18%', marginRight: theme.spacing(0.5) },
                            }}
                            value={zipcode}
                            onChange={(value) => setZipcode(value)}
                            label={t.zipcode}
                        />
                        <TextInput
                            FormGroupProps={{
                                sx: { flex: '1 1 80%' },
                            }}
                            value={location}
                            onChange={(value) => setLocation(value)}
                            label={t.postalLocation}
                        />
                    </Layout>
                    <CountrySelect country={countryIso2Code} onChange={setCountry} />
                    <Layout spacing="dense" justify="center">
                        {!spinner ? (
                            <Button size="large" onClick={handleSave} text="Lagre" />
                        ) : (
                            <CircularProgress />
                        )}
                    </Layout>
                </Layout>
            </Panel>
        </Fragment>
    );
}
