import { Link as MuiLink, SvgIcon } from '@mui/material';

import { useCustomizations } from 'src/components/utils/theme/customizations';

type LinkProps = {
    Icon?: typeof SvgIcon;
    iconPlacement?: 'start' | 'end';
    text: string;
    href: string;
    color?: 'primary' | 'secondary' | 'link';
};

export default function Link(props: LinkProps) {
    const customizations = useCustomizations();
    const { Icon, text, href, color = 'link', iconPlacement = 'start' } = props;

    return (
        <MuiLink
            href={href}
            sx={{
                cursor: 'pointer',
                display: 'inline-flex',
                alignItems: 'center',
                color: `${customizations.linkColor} !important`,
            }}
            underline="hover"
            target="_blank"
        >
            {Icon && iconPlacement === 'start' && (
                <Icon sx={{ color: customizations.linkColor, marginRight: 0.5 }} />
            )}
            {text}
            {Icon && iconPlacement === 'end' && <Icon sx={{ color: customizations.linkColor }} />}
        </MuiLink>
    );
}
