import { useMediaQuery, useTheme } from '@mui/material';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import LoggedInPanel from 'src/components/domain/timeslots-authentication/logged-in-panel/LoggedInPanel';
import LoggedOutPanel from 'src/components/domain/timeslots-authentication/logged-out-panel/LoggedOutPanel';
import LoginButton from 'src/components/domain/timeslots-authentication/login-button/LoginButton';
import ContactPanel from 'src/components/domain/timeslots-contact-panel/ContactPanel';
import ValueCardSummaryPanel from 'src/components/domain/timeslots-summary-panel/ValueCardSummaryPanel';
import { useLocale } from '@repo/i18n';
import { companyAtom, isCompany } from 'src/state/company';
import { contactAtom } from 'src/state/contactInfo';
import { useAtomReducer } from 'src/hooks';
import { createValueCardReservation, valueCardAtom } from 'src/state/valueCard';
import {
    ValueCardCheckoutTabIndex,
    valueCardCheckoutTabStateAtom,
} from 'src/state/valueCardCheckoutTab';
import { contactToUser } from '@repo/widget-utils/traversals/membership-transformers';
import { showError } from 'src/utils/widget/error-handling';
import { updateUser } from '@repo/widget-utils/services/fetchers/membership';
import { isContactInfoValid } from '@repo/widget-utils/timeslots/contactValidation';
import { useAtom } from 'ximple';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useCartContext } from 'src/widgets/CartContext';

export default function ContactView() {
    const { t } = useLocale();
    const theme = useTheme();
    const { loggedInUser, mutateValueCardsForUser } = useMemberContext();
    const [, valueCardTabDispatch] = useAtomReducer(valueCardCheckoutTabStateAtom);
    const [contactInfo, contactDispatch] = useAtomReducer(contactAtom);
    const [valueCardState, valueCardDispatch] = useAtomReducer(valueCardAtom);
    const [company] = useAtom(companyAtom);

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const { giftcards, appliedPromoCode } = useCartContext();

    const validate = () => {
        const [isValid, validationDetails] = isContactInfoValid(contactInfo);
        contactDispatch({ type: 'UPDATE_VALIDATION', value: validationDetails });
        if (!isValid) {
            showError(t.notAllFieldsHaveBeenFilledPleaseFillAllFields);
        }
        return isValid;
    };

    const handleGotoPayment = async () => {
        if (!validate() || !isCompany(company)) return;
        if (!loggedInUser) return;
        const user = await updateUser(contactToUser(contactInfo));
        if (!user) return;

        const giftcardReferences = giftcards.map((giftcard) => giftcard.id);
        const promoCodeReference = appliedPromoCode ? appliedPromoCode.coupon_code : null;

        const reservation = await createValueCardReservation(
            valueCardState,
            user,
            company,
            giftcardReferences,
            promoCodeReference,
        );
        valueCardDispatch({ type: 'SET_RESERVATION', value: reservation });
        mutateValueCardsForUser();
        valueCardTabDispatch({
            type: 'GOTO_VALUE_CARD_TAB_BY_INDEX',
            value: ValueCardCheckoutTabIndex.Payment,
        });
    };

    const onCancel = () => {
        contactDispatch({ type: 'RESET' });
        valueCardDispatch({ type: 'CANCEL' });
        window.location.href = window.location.origin + window.location.pathname;
    };

    return (
        <Layout
            flexDirection="column"
            sx={{ maxWidth: '100%' }}
            mobileSx={{ width: '100%', boxSizing: 'border-box' }}
        >
            <Layout mobileFlexDirection="column" spacing="snug">
                <Layout
                    flexDirection="column"
                    spacing="snug"
                    sx={{
                        width: '550px',
                        boxSizing: 'border-box',
                        [theme.breakpoints.down('lg')]: {
                            width: '100%',
                        },
                    }}
                >
                    {loggedInUser ? <LoggedInPanel /> : <LoggedOutPanel checkout="valueCard" />}
                    <ContactPanel />
                    {!isMobile && (
                        <Layout justify="space-between">
                            <Button
                                color="secondary"
                                variant="outlined"
                                onClick={onCancel}
                                text={t.cancel}
                            />
                            {loggedInUser && (
                                <Button
                                    variant="contained"
                                    onClick={handleGotoPayment}
                                    text={t.next}
                                />
                            )}
                            {!loggedInUser && (
                                <LoginButton
                                    variant="signup"
                                    render={(onClick: () => void) => (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => validate() && onClick()}
                                            text={t.nextCreateAccount}
                                        />
                                    )}
                                />
                            )}
                        </Layout>
                    )}
                </Layout>
                <ValueCardSummaryPanel valueCardState={valueCardState} />
                {isMobile && (
                    <Layout justify="space-between">
                        <Button
                            color="secondary"
                            variant="outlined"
                            onClick={onCancel}
                            text={t.cancel}
                        />
                        {loggedInUser && (
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleGotoPayment}
                                text={t.next}
                            />
                        )}
                        {!loggedInUser && (
                            <LoginButton
                                variant="signup"
                                render={(onClick: () => void) => (
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={() => validate() && onClick()}
                                        text={t.nextCreateAccount}
                                    />
                                )}
                            />
                        )}
                    </Layout>
                )}
            </Layout>
        </Layout>
    );
}
