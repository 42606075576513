import { useTheme } from '@mui/material';

type SeparatorProps = {
    marginTop?: string;
    marginBottom?: string;
};

export default function Separator({ marginTop, marginBottom }: SeparatorProps) {
    const theme = useTheme();
    return (
        <hr
            style={{
                width: '100%',
                border: `1px solid ${theme.palette.grey['100']}`,
                margin: '0',
                marginTop,
                marginBottom,
            }}
        />
    );
}
