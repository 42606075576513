import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';

import { useState } from 'react';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import QrViewer from 'src/components/domain/timeslots-qr-viewer/QrViewer';
import { useLocale } from '@repo/i18n';
import { BUILD_VERSION } from '@repo/widget-env/__autogen/env';

const baseUrl =
    (BUILD_VERSION as any) === 'production'
        ? 'https://bilberry-timeslots-onsite.bilberry.no/'
        : 'https://bilberry-timeslots-onsite-test.bilberry.no/';

export function ValueCardQRCode(props: {
    orderReference: string;
    qrReference: string;
    buttonSx?: SxProps<any>;
}) {
    const { orderReference, qrReference, buttonSx } = props;
    const { t } = useLocale();
    const [open, setOpen] = useState(false);

    const url = `${baseUrl}/?orderReference=${qrReference !== '' ? qrReference : orderReference}`;

    return (
        <Box sx={{ py: 2 }}>
            <Button text={t.scanTicket} sx={{ ...buttonSx }} onClick={() => setOpen(true)} />
            <QrViewer
                open={open}
                setOpen={setOpen}
                orderReference={qrReference !== '' ? qrReference : orderReference}
                url={url}
            />
        </Box>
    );
}
