import { SxProps } from '@mui/material';
import { useState } from 'react';
import { ReactComponent as CreditsIcon } from 'src/assets/icons/credits.svg';
import RadioPanel from 'src/components/common/timeslots-radio-panel/RadioPanel';
import { useLocale } from '@repo/i18n';
import { MembershipUserValueCard, OrderStatusEnum, Translations } from '@repo/types';
import CreditsInfoValueCard from './CreditsInfoValueCard';

type CreditsInfo = {
    credits: MembershipUserValueCard;
    buttonSx?: SxProps;
};

const getPanelSubtext = (valueCard: MembershipUserValueCard, t: Translations) => {
    switch (valueCard.purchaseOrderStatus) {
        case OrderStatusEnum.Completed:
            return (
                (valueCard.balance?.creditsAvailable
                    ? valueCard.balance.creditsAvailable / 100
                    : 0) +
                ' ' +
                t.creditsLeft
            );
        case OrderStatusEnum.AwaitingPayment:
            return t.order_status_awaiting_payment;
        case OrderStatusEnum.PaymentFailed:
            return t.order_status_payment_failed;
        default:
            throw new Error(`Unexpected order status ${valueCard.purchaseOrderStatus}`);
    }
};

export default function CreditsInfo({ credits, buttonSx }: CreditsInfo) {
    const { t } = useLocale();
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const panelText = `${credits.valueCardProduct.productType.name} - ${credits.valueCardProduct.name}`;
    const panelSubtext = getPanelSubtext(credits, t);

    return (
        <RadioPanel
            Icon={CreditsIcon as any}
            text={panelText}
            setIsOpen={setIsPanelOpen}
            subtext={panelSubtext}
            subtitle={
                credits.purchaseSite &&
                credits.valueCardProduct.configuration.isRestrictedToPurchaseSite
                    ? `${credits.purchaseSite}`
                    : undefined
            }
            variant="normal"
        >
            {isPanelOpen && (
                <CreditsInfoValueCard credits={credits} buttonSx={buttonSx}></CreditsInfoValueCard>
            )}
        </RadioPanel>
    );
}
