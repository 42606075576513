import { Button as MuiButton, SvgIconTypeMap, useTheme } from '@mui/material';
import type { OverridableComponent } from '@mui/material/OverridableComponent';

type ImportantDescisionButtonProps = {
    Icon?: OverridableComponent<SvgIconTypeMap<Record<string, unknown>, 'svg'>>;
    text: string;
    onClick: () => void;
    disabled?: boolean;
};

export default function ImportantDecisionButton({
    Icon,
    text,
    onClick,
    disabled,
}: ImportantDescisionButtonProps) {
    const theme = useTheme();
    const color = theme.palette.primary.main;
    const ColoredIcon = (Icon ? <Icon sx={{ color }} /> : undefined) as JSX.Element;

    return (
        <MuiButton
            startIcon={ColoredIcon}
            onClick={onClick}
            disabled={disabled}
            sx={(theme) => ({
                background: theme.palette.grey['100'],
                color: theme.typography.body1.color,
                border: '2px solid',
                borderColor: 'black',
            })}
        >
            {text}
        </MuiButton>
    );
}
