import flow from 'lodash-es/flow';
import { MembershipValueCardProduct, DiscountSource } from '@repo/types';
import { isDiscountSource, withDiscounts } from '../discounts/price';

/** Get the total currency in NOK to pay for a value card, sans any applicable discounts
 * @param valueCard The valuecard for which to find the price
 */
export function getTotalPrice(valueCard?: MembershipValueCardProduct) {
    return (valueCard?.price ?? 0) / 100;
}

/** Get the total currency in NOK to pay for a booking after reducing the price with any applicable discounts
 * @param valueCard The value card for which to potentially reduce the price
 * @param discounts The various discount source objects, which specify a balance and a reduction type (percentage/absolute)
 */
export const getTotalPriceWithReductions = (
    valueCard: MembershipValueCardProduct | undefined,
    discounts: (DiscountSource | undefined)[],
) =>
    flow<[MembershipValueCardProduct | undefined], number, number>(
        getTotalPrice,
        withDiscounts(discounts.filter(isDiscountSource)),
    )(valueCard);

/** Get the lowest price in NOK in a list of value cards.
 * @param valueCards The list of value cards to find the lowest price from
 */
export const getLowestPrice = (valueCards: MembershipValueCardProduct[]) =>
    valueCards.reduce((acc, card) => Math.min(acc, card.price), Number.POSITIVE_INFINITY);
