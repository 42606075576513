import { Box, Slide, useTheme } from '@mui/material';

import { useContext, useEffect, useRef } from 'react';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useAtomReducer } from 'src/hooks';
import { uiStateAtom } from 'src/state/ui-timeslots';
import { ValueCardPurchase } from 'src/state/valueCard';
import { MountPointContext } from '@repo/common-utils/mountPoint';
import ValueCardPopover from '../../value-card-containers/value-card-popover/ValueCardPopover';
import { calendarContainerStyles } from '../../value-card-styles/ValueCardStyles';
import ValueCardDates from '../value-card-dates/ValueCardDates';

type ValueCardCalendarProps = {
    anchorEl: any;
    valueCardState: ValueCardPurchase;
    setValueCardState: (state: ValueCardPurchase) => void;
    inputId?: string;
};

export default function ValueCardCalendar(props: ValueCardCalendarProps) {
    const { anchorEl, valueCardState, setValueCardState, inputId } = props;
    const theme = useTheme();
    const [{ valueCardPurchase }, dispatcher] = useAtomReducer(uiStateAtom);
    const { calendarVisible } = valueCardPurchase;
    const mountPoint = useContext(MountPointContext);
    const popoverRef = useRef<HTMLDivElement | null>(null);

    const trap = useFocusTrap(popoverRef, calendarVisible, `#${inputId}`, mountPoint.shadowRoot);

    useEffect(() => {
        if (trap && !calendarVisible) trap.deactivate();
    }, [trap, calendarVisible]);

    const handleClose = () => {
        dispatcher({ type: 'SET_VALUE_CARD_CALENDAR_VISIBILITY', value: false });
    };

    return (
        <ValueCardPopover
            anchorEl={anchorEl}
            visible={calendarVisible}
            handleClose={handleClose}
            forwardRef={popoverRef}
        >
            <Slide direction="right" in={true}>
                <Box sx={calendarContainerStyles(theme)}>
                    <ValueCardDates
                        valueCardState={valueCardState}
                        setValueCardState={setValueCardState}
                    />
                </Box>
            </Slide>
        </ValueCardPopover>
    );
}
