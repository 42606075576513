import { SxProps } from '@mui/material';
import produce from 'immer';

import { useState } from 'react';
import { Dropdown } from 'src/components/common/dropdown/Dropdown';
import { useLocale } from '@repo/i18n';
import { ValueCardPurchase } from 'src/state/valueCard';
import { MembershipValueCardProduct } from '@repo/types';
import { useCustomizations } from 'src/components/utils/theme/customizations';

type MembershipTypeSelectorProps = {
    onClick?: (e: any) => void;
    visible?: boolean;
    memberships: MembershipValueCardProduct[];
    valueCardState: ValueCardPurchase;
    setValueCardState: (state: ValueCardPurchase) => void;
    buyNowClicked: boolean;
    sx?: SxProps<any>;
    variant?: 'contrast' | 'regular';
};

export default function MembershipTypeSelectorProps(props: MembershipTypeSelectorProps) {
    const { valueCardState, setValueCardState, buyNowClicked, memberships, variant } = props;
    const { t } = useLocale();
    const { membership } = valueCardState;
    const [selectedMembership, setSelectedMembership] = useState(
        memberships.find((x) => x.id === membership.id) ?? null,
    );

    const showValidationError = buyNowClicked && membership.id === -1;
    const handleValueCardChange = (value: MembershipValueCardProduct | null) => {
        setSelectedMembership(value);
        setValueCardState(
            produce(valueCardState, (draft: ValueCardPurchase) => {
                draft.membership.id = value?.id ?? -1;
            }),
        );
    };

    const customizations = useCustomizations();

    return (
        <Dropdown
            variant={variant === 'contrast' ? 'booking-card' : 'outlined'}
            label={t.chooseType}
            labelColor={
                variant === 'contrast' ? customizations.bookingWidgetColorContrast : undefined
            }
            options={memberships}
            value={selectedMembership}
            onChange={handleValueCardChange}
            getTextFieldLabel={(p) =>
                `${p?.name ?? ''}${
                    p?.valueCardProductCategory ? ` - ${p.valueCardProductCategory.name}` : ''
                }`
            }
            getOptionLabel={(p) =>
                `${p?.name ?? ''}${
                    p?.valueCardProductCategory ? ` - ${p.valueCardProductCategory.name}` : ''
                }`
            }
            defaultValue={t.choose}
            color="secondary"
            error={showValidationError ? t.pleaseSelectAMembershipType : ''}
            sx={props.sx}
        />
    );
}
