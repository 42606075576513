import { tzdate } from '@repo/tzdate';
import { ReactComponent as MembershipIcon } from 'src/assets/icons/membership.svg';
import RadioPanel from 'src/components/common/timeslots-radio-panel/RadioPanel';
import { MembershipUserValueCard, OrderStatusEnum, Translations } from '@repo/types';
import MembershipInfoValueCard from './MembershipInfoValueCard';
import { useLocale } from '@repo/i18n';
import {
    findValueCardValidToDisplayDateText,
    getValidFromDisplayText,
    getValidUntilDisplayText,
} from '@repo/widget-utils/display-helper';

type MembershipInfoProps = {
    handleCancelMembership: () => void;
    membership: MembershipUserValueCard;
};

const getPanelSubtext = (valueCard: MembershipUserValueCard, t: Translations) => {
    const now = tzdate();
    const isValidYet = !valueCard.validFrom || tzdate(valueCard.validFrom, false).isBefore(now);
    const validToDisplayDateText = findValueCardValidToDisplayDateText(valueCard);

    switch (valueCard.purchaseOrderStatus) {
        case OrderStatusEnum.Completed:
            return isValidYet
                ? getValidUntilDisplayText(validToDisplayDateText, t)
                : getValidFromDisplayText(valueCard.validFrom, t);
        case OrderStatusEnum.AwaitingPayment:
            return t.order_status_awaiting_payment;
        case OrderStatusEnum.PaymentFailed:
            return t.order_status_payment_failed;
        default:
            throw new Error(`Unexpected order status ${valueCard.purchaseOrderStatus}`);
    }
};

export default function MembershipInfo({
    handleCancelMembership,
    membership,
}: MembershipInfoProps) {
    const { t } = useLocale();

    const panelText = `${membership.valueCardProduct.productType.name} - ${membership.valueCardProduct.name}`;
    const panelSubtext = getPanelSubtext(membership, t);

    const { isRestrictedToPurchaseSite } = membership.valueCardProduct.configuration;
    const panelSubtitle = isRestrictedToPurchaseSite ? `${membership.purchaseSite}` : undefined;

    const cancellation = membership.subscription
        ? {
              fromDate:
                  tzdate(membership.subscription.cancelSubscriptionAllowedFrom, false) ?? null,
              nextChargeAt: membership.subscription.nextSubscriptionChargeAt
                  ? (tzdate(membership.subscription.nextSubscriptionChargeAt, false) ?? null)
                  : null,
              cancelledFrom: membership.subscription.subscriptionCancelledFrom
                  ? (tzdate(membership.subscription.subscriptionCancelledFrom, false) ?? null)
                  : null,
          }
        : undefined;

    return (
        <RadioPanel
            Icon={MembershipIcon as any}
            text={panelText}
            subtext={panelSubtext}
            subtitle={panelSubtitle}
            variant="normal"
        >
            <MembershipInfoValueCard
                membership={membership}
                handleCancelMembership={handleCancelMembership}
                cancellation={cancellation}
            ></MembershipInfoValueCard>
        </RadioPanel>
    );
}
