import produce from 'immer';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { atom } from 'ximple';
import { valueCardAtom } from './valueCard';

export type ValueCardCheckoutTabState = {
    valueCardCheckout: {
        tabIndex: number;
    };
};

export enum ValueCardCheckoutTabIndex {
    Selection = 0,
    Contact = 1,
    Payment = 2,
}

type Actions =
    | { type: 'INITIALIZE' }
    | { type: 'GOTO_VALUE_CARD_SELECTION' }
    | { type: 'GOTO_VALUE_CARD_CONTACT_INFO' }
    | { type: 'GOTO_VALUE_CARD_PAYMENT' }
    | {
          type: 'GOTO_VALUE_CARD_TAB_BY_INDEX';
          value: ValueCardCheckoutTabState['valueCardCheckout']['tabIndex'];
      }
    | { type: 'RESET_UI' };

const initialValueCardCheckoutTabStateAtom: ValueCardCheckoutTabState = {
    valueCardCheckout: { tabIndex: 0 },
};

const uiStateReducer = produce((draft: ValueCardCheckoutTabState, action: Actions) => {
    switch (action.type) {
        case 'INITIALIZE':
            return {
                valueCardCheckout: {
                    tabIndex: draft.valueCardCheckout.tabIndex,
                },
            };
        case 'GOTO_VALUE_CARD_SELECTION':
            draft.valueCardCheckout.tabIndex = ValueCardCheckoutTabIndex.Selection;
            break;
        case 'GOTO_VALUE_CARD_CONTACT_INFO':
            draft.valueCardCheckout.tabIndex = ValueCardCheckoutTabIndex.Contact;
            break;
        case 'GOTO_VALUE_CARD_PAYMENT':
            draft.valueCardCheckout.tabIndex = ValueCardCheckoutTabIndex.Payment;
            break;
        case 'GOTO_VALUE_CARD_TAB_BY_INDEX':
            draft.valueCardCheckout.tabIndex = action.value;
            break;
        case 'RESET_UI':
            return initialValueCardCheckoutTabStateAtom;
    }
});

function updateValueCardCheckoutTabState(state: ValueCardCheckoutTabState, action: Actions) {
    const newState = uiStateReducer(state, action);

    switch (action.type) {
        case 'GOTO_VALUE_CARD_CONTACT_INFO':
        case 'GOTO_VALUE_CARD_PAYMENT':
        case 'GOTO_VALUE_CARD_TAB_BY_INDEX':
            if (newState.valueCardCheckout.tabIndex === ValueCardCheckoutTabIndex.Contact) {
                dispatchWidgetEvent({
                    eventType: 'checkoutStep',
                    checkoutStep: 'Contact Info',
                    purchaseProduct: valueCardAtom.subject.value,
                });
            } else if (newState.valueCardCheckout.tabIndex === ValueCardCheckoutTabIndex.Payment) {
                dispatchWidgetEvent({
                    eventType: 'checkoutStep',
                    checkoutStep: 'Payment',
                    purchaseProduct: valueCardAtom.subject.value,
                });
                break;
            }
    }

    return newState;
}

export const valueCardCheckoutTabStateAtom = atom<ValueCardCheckoutTabState, Actions>({
    initialValue: initialValueCardCheckoutTabStateAtom,
    persistKey: 'no.bilberry-timeslots.value-card-checkout-tab',
    update: updateValueCardCheckoutTabState,
});
