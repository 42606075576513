import { Box, useTheme, Theme, useMediaQuery } from '@mui/material';
import { createRef, useEffect } from 'react';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import Title from 'src/components/common/timeslots-typography/Title';
import Overlay from 'src/components/common/overlay/Overlay';
import BackButton from 'src/components/domain/checkout/BackButton';

type PageProps = {
    children: React.ReactNode[] | React.ReactNode;
    title?: string;
    description?: string;
    backButtonOnClick?: () => void;
    backButtonText?: string;
    hideBackButton?: boolean;
};

export default function Page({
    children,
    title,
    description,
    backButtonOnClick,
    backButtonText,
    hideBackButton,
}: PageProps) {
    const theme = useTheme();
    const config = useConfigurations();
    const customizations = useCustomizations();
    const logoString = config.logo;
    const sentinelRef = createRef<HTMLDivElement>();
    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    useEffect(() => {
        sentinelRef.current?.focus();
    }, [sentinelRef]);

    return (
        <Overlay>
            <Box sx={{ marginBottom: theme.spacing(15) }}>
                <Box
                    sx={{
                        position: 'relative',
                        backgroundColor: customizations.checkoutHeaderColor, //theme.palette.grey[50],
                        color: customizations.checkoutHeaderColorContrast,
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: theme.spacing(15),
                        '& .MuiTabs-flexContainer': {
                            borderBottom: customizations.navigationTabBorderBottom,
                        },

                        [theme.breakpoints.up('md')]: {
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            '& img': {
                                marginLeft: theme.spacing(3),
                            },
                        },
                        [theme.breakpoints.down('md')]: {
                            '& img': {
                                marginTop: theme.spacing(2),
                            },
                        },
                        [theme.breakpoints.up('lg')]: {
                            flexDirection: 'row',
                            height: theme.spacing(10),

                            '& img': {
                                position: 'absolute',
                                marginLeft: theme.spacing(3),
                            },
                        },
                        [theme.breakpoints.down('lg')]: {
                            '& img': {
                                height: theme.spacing(6.25),
                                marginLeft: theme.spacing(2),
                            },
                        },
                    }}
                >
                    {logoString && (
                        <img
                            onClick={() => history.back()}
                            src={logoString}
                            style={{
                                cursor: 'pointer',
                                alignSelf: 'center',
                                maxHeight: theme.spacing(10),
                            }}
                        />
                    )}
                </Box>
                <div ref={sentinelRef} tabIndex={0} />
                <Box position="relative" m="0 auto" maxWidth={`calc(1100px + ${theme.spacing(2)})`}>
                    <Box>
                        <Box
                            sx={{
                                px: theme.spacing(1),
                                mt: theme.spacing(4),
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            {!hideBackButton && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        width: '100%',
                                        mb: 1,
                                    }}
                                >
                                    <BackButton
                                        label={backButtonText}
                                        onClick={
                                            typeof backButtonOnClick !== 'undefined'
                                                ? backButtonOnClick
                                                : undefined
                                        }
                                    />
                                </Box>
                            )}
                            {title && (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: largeScreen ? 'flex-start' : 'center',
                                        width: '100%',
                                        mb: 1,
                                    }}
                                >
                                    <Title text={title} description={description ?? ''} />
                                </Box>
                            )}
                            {children}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Overlay>
    );
}
