import { Box, CircularProgress, darken, SxProps, useTheme } from '@mui/material';
import { route } from 'src/components/common/router/Router';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Title from 'src/components/common/timeslots-typography/Title';
import LoginButton from 'src/components/domain/timeslots-authentication/login-button/LoginButton';
import UpcomingBookings from 'src/components/domain/timeslots-booking/upcoming-bookings/UpcomingBookings';
import MembershipPanel from 'src/components/domain/timeslots-membership-panel/MembershipPanel';
import MyProfile from 'src/components/domain/timeslots-my-profile/MyProfile';
import { useLocale } from '@repo/i18n';
import { useAtomReducer } from 'src/hooks';
import { uiStateAtom } from 'src/state/ui-timeslots';
import { valueCardAtom } from 'src/state/valueCard';
import {
    ValueCardCheckoutTabIndex,
    valueCardCheckoutTabStateAtom,
} from 'src/state/valueCardCheckoutTab';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { ThemeType } from 'src/components/utils/theme/ThemeType';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { MembershipUser, MembershipUserValueCard } from '@repo/types';

export default function ProfileView() {
    const { loggedInUser, valueCardsForUser, isLoadingValueCardsForUser, mutateLoggedInUser } =
        useMemberContext();

    return (
        <Layout
            flexDirection="column"
            spacing="wide"
            sx={{
                maxWidth: '1280px',
                width: '100%',
                margin: '0 auto',
            }}
        >
            {!loggedInUser && <Loading></Loading>}

            {loggedInUser && (
                <Loaded
                    user={loggedInUser}
                    valueCards={valueCardsForUser}
                    onUserUpdated={mutateLoggedInUser}
                    isValueCardsLoading={isLoadingValueCardsForUser}
                ></Loaded>
            )}
        </Layout>
    );
}

function Loading() {
    return <CircularProgress></CircularProgress>;
}

function Loaded(props: {
    user: MembershipUser;
    valueCards: MembershipUserValueCard[];
    isValueCardsLoading: boolean;
    onUserUpdated: (user: MembershipUser) => void;
}) {
    const { user, valueCards, isValueCardsLoading } = props;
    const name = user.firstName + ' ' + user.lastName;
    const { t } = useLocale();
    const theme = useTheme();
    const customizations = useCustomizations();
    const buttonSx = createButtonSx(customizations);

    return (
        <>
            <Layout
                justify="space-between"
                alignItems="center"
                spacing="snug"
                mobileSx={{
                    marginLeft: `${theme.spacing(0)} !important`,
                    marginRight: `${theme.spacing(0)} !important`,
                }}
            >
                <Title text={t.myPage} description={name} separator="-" />
                <Box sx={{ my: theme.spacing(2) }}>
                    <LoginButton leavePageOnLogout useLoggedOutStyling />
                </Box>
            </Layout>
            <Layout justify="space-between" mobileFlexDirection="column-reverse">
                <Layout
                    flexDirection="column"
                    spacing="wide"
                    sx={{
                        width: '550px',
                        boxSizing: 'border-box',
                    }}
                    mobileSx={{ width: '100%' }}
                >
                    <UpcomingBookings />
                    <MembershipPanel
                        buttonSx={buttonSx}
                        valueCards={valueCards}
                        isValueCardsLoading={isValueCardsLoading}
                    />
                    <Layout mobileSx={{ justifyContent: 'center' }}>
                        <PurchaseValueCardButton buttonSx={buttonSx} />
                    </Layout>
                </Layout>
                <MyProfile user={user} onUserUpdated={props.onUserUpdated} />
            </Layout>
        </>
    );
}

function PurchaseValueCardButton(props: { buttonSx: SxProps }) {
    const { t } = useLocale();
    const [, valueCardDispatch] = useAtomReducer(valueCardAtom);
    const [, uiDispatch] = useAtomReducer(uiStateAtom);
    const [, valueCardTabDispatch] = useAtomReducer(valueCardCheckoutTabStateAtom);

    const handlePurchaseValueCard = () => {
        valueCardDispatch({ type: 'RESET' });
        uiDispatch({ type: 'SET_CURRENT_USERFLOW', value: 'profile' });
        valueCardTabDispatch({
            type: 'GOTO_VALUE_CARD_TAB_BY_INDEX',
            value: ValueCardCheckoutTabIndex.Selection,
        });
        route('/value-card');
    };

    return (
        <Button text={t.purchaseValueCard} onClick={handlePurchaseValueCard} sx={props.buttonSx} />
    );
}

function createButtonSx(customizations: ThemeType) {
    return {
        backgroundColor: customizations.primaryColor,
        color: customizations.primaryColorContrast,
        '&:hover': {
            '@media (hover: hover)': {
                backgroundColor: darken(customizations.primaryColor, 0.1),
                color: customizations.primaryColorContrast,
            },
        },
        '&:disabled': {
            '@media (hover: hover)': {
                backgroundColor: customizations.primaryColor,
                color: customizations.primaryColorContrast,
                opacity: '50%',
            },
        },
    };
}
