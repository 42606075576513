import { CircularProgress, SxProps } from '@mui/material';
import { Fragment } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import { cancelMembership } from '@repo/widget-utils/services/fetchers/membership';
import CreditsInfo from './CreditsInfo';
import MembershipInfo from './MembershipInfo';
import { MembershipUserValueCard } from '@repo/types';

export default function MembershipPanel(props: {
    buttonSx?: SxProps;
    valueCards: MembershipUserValueCard[];
    isValueCardsLoading: boolean;
}) {
    const { buttonSx } = props;
    const { t } = useLocale();

    const handleCancelMembership = async (valueCardId: number) => {
        await cancelMembership(valueCardId);
    };

    const valueCardsWithNoCreditLimit = props.valueCards.filter(
        (x) => x.valueCardProduct.configuration.creditLimit === null,
    );

    const valueCardsWithCredits = props.valueCards.filter(
        (x) =>
            x.valueCardProduct.configuration.creditLimit &&
            x.balance !== null &&
            x.balance.creditsAvailable > 0,
    );

    return (
        <Fragment>
            <Layout flexDirection="column">
                <PanelTitle variant="h2">{t.membershipSlashCredits}</PanelTitle>
                <Layout flexDirection="column" spacing="small">
                    {valueCardsWithNoCreditLimit.map((periodCard) => {
                        return (
                            <MembershipInfo
                                key={`membership-info-${periodCard.id}`}
                                handleCancelMembership={() => handleCancelMembership(periodCard.id)}
                                membership={periodCard}
                            />
                        );
                    })}
                    {valueCardsWithCredits.map((credits) => {
                        return (
                            <CreditsInfo
                                key={`credits-${credits.id}`}
                                credits={credits}
                                buttonSx={{ ...buttonSx }}
                            />
                        );
                    })}
                    {!props.isValueCardsLoading && props.valueCards.length === 0 && (
                        <Text>{t.youDontHaveAnyValueCards}</Text>
                    )}
                    {props.isValueCardsLoading && <CircularProgress color="primary" />}
                </Layout>
            </Layout>
        </Fragment>
    );
}
