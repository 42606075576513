import { useMemo } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import { companyAtom } from 'src/state/company';
import { initialValueCardAtom, ValueCardPurchase } from 'src/state/valueCard';
import { valueCardGroups } from '@repo/widget-utils/traversals/valueCard';
import { useAtom } from 'ximple';
import CreditsPanel from './CreditsPanel';
import MembershipPanel from './MembershipPanel';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export default function ValueCardSelectionPanel({
    valueCardState,
    setValueCardState,
}: {
    valueCardState: ValueCardPurchase;
    setValueCardState: (value: ValueCardPurchase) => void;
}) {
    const [{ company }] = useAtom(companyAtom);

    const { loggedInUser, availableValueCardProductsForUser } = useMemberContext();

    const valueCardProducts =
        loggedInUser && availableValueCardProductsForUser.length > 0
            ? availableValueCardProductsForUser
            : company?.valueCardProducts;
    const { memberships, credits } = useMemo(
        () => valueCardGroups(valueCardProducts ?? []),
        [valueCardProducts],
    );
    const membershipGroups = useMemo(
        () =>
            company?.valueCardProductTypes
                .map((type) => ({
                    id: type.id,
                    title: type.name,
                    cards: memberships.filter((membership) => membership.productTypeId === type.id),
                }))
                .filter(({ cards }) => cards.length > 0),
        [company, memberships],
    );

    const creditGroups = useMemo(
        () =>
            company?.valueCardProductTypes
                .map((type) => ({
                    id: type.id,
                    title: type.name,
                    cards: credits.filter((credit) => credit.productTypeId === type.id),
                }))
                .filter(({ cards }) => cards.length > 0),
        [company, credits],
    );

    const handleMembershipCategory = (typeId: number) =>
        setValueCardState({
            ...initialValueCardAtom,
            typeId: typeId,
            credits: { id: -1, name: '' },
            category: 'membership',
        });
    const handleCreditsCategory = (typeId: number) =>
        setValueCardState({
            ...initialValueCardAtom,
            typeId: typeId,
            membership: { id: -1, dateTime: { date: '', time: '' } },
            category: 'credits',
        });
    return (
        <Layout flexDirection="column" spacing="snug">
            {membershipGroups?.map(({ id, title, cards }) => (
                <MembershipPanel
                    key={id}
                    title={title}
                    memberships={cards}
                    isChecked={valueCardState.typeId === id}
                    onChange={() => handleMembershipCategory(id)}
                    valueCardState={valueCardState}
                    setValueCardState={setValueCardState}
                />
            ))}
            {creditGroups?.map(({ id, title, cards }) => (
                <CreditsPanel
                    key={id}
                    title={title}
                    credits={cards}
                    isChecked={valueCardState.typeId === id}
                    onChange={() => handleCreditsCategory(id)}
                    valueCardState={valueCardState}
                    setValueCardState={setValueCardState}
                />
            ))}
        </Layout>
    );
}
