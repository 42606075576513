import { route } from 'src/components/common/router/Router';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { useFocusTrap } from 'src/hooks/common/useFocusTrap';
import { useLocale } from '@repo/i18n';
import { companyAtom } from 'src/state/company';
import { contactAtom } from 'src/state/contactInfo';
import { useAtomReducer } from 'src/hooks';
import { uiStateAtom } from 'src/state/ui-timeslots';
import { toggleBookingAtom } from 'src/state/ui/toggleBooking.atom';
import { initialValueCardAtom, valueCardAtom, ValueCardPurchase } from 'src/state/valueCard';
import { valueCardCheckoutTabStateAtom } from 'src/state/valueCardCheckoutTab';
import { valueCardsByType } from '@repo/widget-utils/traversals/valueCard';
import { MembershipValueCardProduct, TicketOptionWithQuantity } from '@repo/types';
import { showError } from 'src/utils/widget/error-handling';
import { getSelectedValueCardOrCheapest } from '@repo/widget-utils/value-cards/cards';
import { getLowestPrice } from '@repo/widget-utils/value-cards/price';
import { useAtom } from 'ximple';
import BookingToggleOpenButton from '../booking-widget-card/BookingToggleOpenButton';
import BookingWidgetCard from '../booking-widget-card/BookingWidgetCard';
import ValueCardCostSummary from './value-card-cost-summary/ValueCardCostSummary';
import MembershipDateSelector from './value-card-selectors/MembershipDateSelector';
import MembershipTypeSelector from './value-card-selectors/MembershipTypeSelector';
import { useTheme } from '@mui/material';
import ValueCardCreditProductsSelector from './value-card-selectors/ValueCardCreditProductsSelector';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

type ValueCardProps = {
    cardType: 'membership' | 'credits' | '';
    typeId: number;
    expandArrowInside: boolean;
    positionOffscreen?: boolean;
};

export default function ValueCard({
    cardType,
    typeId,
    expandArrowInside,
    positionOffscreen = false,
}: ValueCardProps): JSX.Element {
    const { t } = useLocale();
    const [valueCardState, setValueCardState] = useState({
        ...initialValueCardAtom,
        category: cardType,
        typeId: typeId,
    });
    const [{ company }] = useAtom(companyAtom);

    const { loggedInUser, availableValueCardProductsForUser } = useMemberContext();

    const valueCardProducts =
        loggedInUser && availableValueCardProductsForUser.length > 0
            ? availableValueCardProductsForUser
            : company?.valueCardProducts;

    const [{ visible, interaction }] = useAtom(toggleBookingAtom);
    const cards = useMemo(
        () => valueCardsByType(valueCardProducts ?? [], typeId),
        [valueCardProducts, typeId],
    );
    const [buyNowClicked, setBuyNowClicked] = useState(false);

    useEffect(() => {
        if (!company || !visible) return;

        const card = getSelectedValueCardOrCheapest(
            company.valueCardProducts,
            typeId,
            valueCardState.typeId,
        );

        if (!card) return;

        dispatchWidgetEvent({
            eventType: 'viewItem',
            productType: 'ValueCard',
            product: card,
        });
    }, [company, visible, typeId, valueCardState.typeId]);

    const handleCheckout = async () => {
        setBuyNowClicked(true);
        const selectedValueCard = valueCardProducts?.find(
            (vcp) =>
                vcp.id === valueCardState.membership.id || vcp.id === valueCardState.credits.id,
        );

        if (
            valueCardState.category === 'membership' &&
            (valueCardState.membership.id === -1 ||
                (selectedValueCard?.configuration.validForDurationInDays &&
                    !valueCardState.membership.dateTime?.date))
        ) {
            showError(t.pleaseProvideAllValueCardInformation);
        } else if (valueCardState.category === 'credits' && valueCardState.credits.id === -1) {
            showError(t.pleaseProvideAllValueCardInformation);
        } else {
            await valueCardAtom.update({ type: 'INITIALIZE', value: valueCardState });
            await valueCardCheckoutTabStateAtom.update({
                type: 'GOTO_VALUE_CARD_CONTACT_INFO',
            });
            await contactAtom.update({ type: 'RESET' });
            route('/value-card');
        }
    };

    // Trap to valueCardPurchase when
    const boxRef = useRef<HTMLDivElement | null>(null);

    const refocusRef = useRef<HTMLElement | null>(null);
    refocusRef.current = document
        .querySelector('bilberry-book-timeslot-button')
        ?.shadowRoot?.querySelector('button') as HTMLElement | null;

    const trap = useFocusTrap(boxRef, visible && interaction === 'keyboard', refocusRef);

    useEffect(() => {
        if (trap && !visible) trap.deactivate();
    }, [trap, visible]);

    const onToggleVisible = useCallback((visible: boolean, interaction: 'keyboard' | 'mouse') => {
        toggleBookingAtom.update({ visible, interaction });
    }, []);

    const currentPrice = getValueCardPrice(valueCardState, cards);
    const quantities = currentPrice
        ? [
              {
                  price: currentPrice,
                  quantity: 1,
                  id: '1',
                  defaultId: '1',
                  fromAge: null,
                  name: '',
                  occupancy: 1,
                  productInstances: [],
                  ticketCategoryId: '1',
                  ticketTypes: [],
                  toAge: Number.MAX_VALUE,
                  vatAmount: 0,
                  vatBreakdown: [],
                  capacity: Number.MAX_VALUE,
                  minEntrants: 0,
                  guests: 1,
                  depositAmount: 0,
                  currentConsumedCapacity: 0,
              } as TicketOptionWithQuantity,
          ]
        : [];

    return (
        <Fragment>
            <BookingWidgetCard
                quantities={quantities}
                expandArrowInside={expandArrowInside}
                bookingCardRef={boxRef}
                hideLeftButton={true}
                visible={visible}
                onToggleVisible={onToggleVisible}
                toggleButton={
                    <BookingToggleOpenButton
                        expandArrowInside={expandArrowInside}
                        visible={visible}
                    />
                }
                title={t.buyNow}
                rightButtonLabel={t.buyNow}
                onClickRightButton={handleCheckout}
                fromPrice={
                    cards.length > 0 && !isAnyValueCardSelected(valueCardState)
                        ? getLowestPrice(cards) / 100
                        : undefined
                }
                positionOffscreen={positionOffscreen}
                isLoadingPrices={false}
            >
                {cardType === 'credits' ? (
                    <Credits
                        credits={cards}
                        valueCardState={valueCardState}
                        setValueCardState={setValueCardState}
                        buyNowClicked={buyNowClicked}
                    />
                ) : (
                    <Membership
                        memberships={cards}
                        valueCardState={valueCardState}
                        setValueCardState={setValueCardState}
                        buyNowClicked={buyNowClicked}
                    />
                )}
                <ValueCardCostSummary
                    header={t.price}
                    valueCards={cards}
                    valueCardState={valueCardState}
                />
            </BookingWidgetCard>
        </Fragment>
    );
}

function Credits({
    credits,
    valueCardState,
    setValueCardState,
    buyNowClicked,
}: {
    credits: MembershipValueCardProduct[];
    valueCardState: ValueCardPurchase;
    setValueCardState: (state: ValueCardPurchase) => void;
    buyNowClicked: boolean;
}) {
    const theme = useTheme();
    const [, dispatch] = useAtomReducer(valueCardAtom);

    useEffect(() => {
        dispatch({ type: 'SET_CREDITS_CATEGORY' });
    }, [dispatch]);

    return (
        <Fragment>
            <Layout flexDirection="column" spacing="snug">
                <ValueCardCreditProductsSelector
                    valueCardProducts={credits}
                    valueCardState={valueCardState}
                    setValueCardState={setValueCardState}
                    buyNowClicked={buyNowClicked}
                    variant="contrast"
                    sx={{
                        '& input': {
                            p: `${theme.spacing(2)} !important`,
                        },
                    }}
                />
            </Layout>
        </Fragment>
    );
}

function Membership({
    memberships,
    valueCardState,
    setValueCardState,
    buyNowClicked,
}: {
    memberships: MembershipValueCardProduct[];
    valueCardState: ValueCardPurchase;
    setValueCardState: (state: ValueCardPurchase) => void;
    buyNowClicked: boolean;
}) {
    const theme = useTheme();
    const [{ valueCardPurchase }, uiDispatch] = useAtomReducer(uiStateAtom);

    const [, dispatch] = useAtomReducer(valueCardAtom);

    useEffect(() => {
        dispatch({ type: 'SET_MEMBERSHIP_CATEGORY' });
    }, [dispatch]);

    const [anchorEl, setAnchorEl] = useState<HTMLDivElement | HTMLInputElement | null>(null);

    const selectedProduct = memberships.find((x) => x.id === valueCardState.membership.id);
    const showStartDateSelector = selectedProduct?.configuration?.validForDurationInDays;

    return (
        <Fragment>
            <Layout flexDirection="column" spacing="snug">
                <MembershipTypeSelector
                    memberships={memberships}
                    valueCardState={valueCardState}
                    setValueCardState={setValueCardState}
                    buyNowClicked={buyNowClicked}
                    variant="contrast"
                    sx={{
                        '& input': {
                            p: `${theme.spacing(2)} !important`,
                        },
                    }}
                />
                {showStartDateSelector && (
                    <MembershipDateSelector
                        onClick={(e) => {
                            uiDispatch({
                                type: 'SET_VALUE_CARD_CALENDAR_VISIBILITY',
                                value: !valueCardPurchase.calendarVisible,
                            });
                            setAnchorEl(e.currentTarget);
                        }}
                        onKeyDown={(e) => {
                            uiDispatch({
                                type: 'SET_VALUE_CARD_CALENDAR_VISIBILITY',
                                value: !valueCardPurchase.calendarVisible,
                            });
                            setAnchorEl(e.currentTarget);
                        }}
                        anchorEl={anchorEl}
                        valueCardState={valueCardState}
                        setValueCardState={setValueCardState}
                        buyNowClicked={buyNowClicked}
                        variant="contrast"
                    />
                )}
            </Layout>
        </Fragment>
    );
}

function isAnyValueCardSelected(valueCardState: ValueCardPurchase) {
    return valueCardState.credits.id >= 0 || valueCardState.membership.id >= 0;
}

function getValueCardPrice(valueCardState: ValueCardPurchase, cards: MembershipValueCardProduct[]) {
    const { category } = valueCardState;
    if (category === '') return;

    const selectedCard = cards.find((card) => card.id === valueCardState[category].id);
    if (!selectedCard) return;
    return selectedCard.price / 100;
}
