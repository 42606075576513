import { Button as MuiButton, SvgIcon, SxProps, useTheme } from '@mui/material';

export interface ButtonProps {
    text: string;
    onClick: (e: any) => void;
    Icon?: typeof SvgIcon;
    variant?: 'outlined' | 'contained';
    disabled?: boolean;
    color?: 'button' | 'primary' | 'secondary';
    size?: 'small' | 'large';
    sx?: SxProps;
}

export default function Button(props: ButtonProps): JSX.Element {
    const {
        text,
        onClick,
        Icon,
        variant = 'contained',
        disabled = false,
        color = 'button',
        size = 'small',
        sx,
    } = props;
    const theme = useTheme();

    const contrastText = variant === 'contained' ? 'contrastText' : 'main';
    const chosenColor =
        color === 'secondary'
            ? theme.palette.secondary[contrastText]
            : theme.palette.primary[contrastText];

    return (
        <MuiButton
            color={color}
            variant={variant}
            disabled={disabled}
            startIcon={Icon ? <Icon /> : undefined}
            onClick={onClick}
            disableElevation
            sx={{
                color: chosenColor,
                fontSize: theme.typography.body1.fontSize,
                padding: size === 'large' ? theme.spacing(1, 4, 1, 4) : undefined,
                minWidth: size === 'large' ? '200px' : undefined,
                ...sx,
            }}
        >
            {text}
        </MuiButton>
    );
}
