import { FormGroup, FormHelperText, InputLabel, OutlinedInput } from '@mui/material';
import { RefObject } from 'react';
import { selectAllInTextInput } from '../inputManipulation';

type EmailInputProps = {
    value: string;
    onChange: (param: string) => void;
    required?: boolean;
    label?: string;
    helperText?: string;
    color?: 'primary' | 'secondary';
    helperTextAlignment?: 'start' | 'end';
    selectAllTextOnFocus?: boolean;
    inputRef?: RefObject<HTMLInputElement | undefined>;
    error?: boolean;
    disabled?: boolean;
};

export default function EmailInput(props: EmailInputProps): JSX.Element {
    const {
        value,
        onChange,
        required = false,
        label,
        helperText,
        color = 'primary',
        helperTextAlignment = 'start',
        selectAllTextOnFocus = false,
        inputRef,
        error,
    } = props;

    const emailInputValueChanged = (e: React.ChangeEvent) => {
        if (e.target instanceof HTMLInputElement) {
            onChange(e.target.value);
        }
    };

    return (
        <FormGroup>
            <InputLabel disableAnimation id="email-input-label" required={required}>
                {label}
            </InputLabel>
            <OutlinedInput
                required={required}
                inputRef={inputRef}
                value={value}
                color={color}
                onChange={emailInputValueChanged}
                onFocus={selectAllTextOnFocus ? selectAllInTextInput : undefined}
                type="email"
                inputProps={{ sx: { color: 'secondary.main' }, maxLength: 100 }}
                size="small"
                name="email"
                aria-describedby="email-input-field"
                sx={{
                    width: '100%',
                    'input:-webkit-autofill': {
                        WebkitBoxShadow: '0 0 0 30px white inset !important',
                    },
                }}
                error={error}
                autoComplete="email"
                disabled={props.disabled}
            />
            <FormHelperText sx={{ ml: helperTextAlignment === 'end' ? 'auto' : undefined }}>
                {helperText}
            </FormHelperText>
        </FormGroup>
    );
}
