import { createRoot } from 'react-dom/client';
import { getMediaQuerySizesFromAttributes } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import ValueCardProductCards from './ValueCardProductCards';

class MembershipValueCardProductCards extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const valueCardTypeIds = this.getAttribute('value-card-type-ids')
            ? this.getAttribute('value-card-type-ids')!
                  .toString()
                  .split(',')
                  .map((num) => Number(num))
            : [];
        const valueCardTypeUrls = this.getAttribute('value-card-type-urls')
            ? this.getAttribute('value-card-type-urls')!.toString().split(',')
            : [];
        const valueCardTypeImageUrls = this.getAttribute('value-card-type-image-urls')
            ? this.getAttribute('value-card-type-image-urls')!.toString().split(',')
            : [];

        const numElements = getMediaQuerySizesFromAttributes(this, 1, 2, 3, 3);
        const scrollAttribute = this.getAttribute('scroll');
        const scroll = scrollAttribute ? scrollAttribute.toString().toLowerCase() === 'yes' : false;

        // finally we need to wrap our application in a StylesProvider
        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ValueCardProductCards
                    typeIds={valueCardTypeIds}
                    imageUrls={valueCardTypeImageUrls}
                    urls={valueCardTypeUrls}
                    numElements={numElements}
                    scroll={scroll}
                />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-value-card-product-cards', MembershipValueCardProductCards);
}
