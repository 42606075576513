import CloseRounded from '@mui/icons-material/CloseRounded';
import { Box, Dialog as MuiDialog, useTheme } from '@mui/material';

import IconButton from 'src/components/common/timeslots-buttons/icon-button/IconButton';
import { ensurePixelValue } from 'src/components/utils/styleUtils';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import { zIndex } from 'src/utils/widget/zIndex';

type DialogProps = {
    children: React.ReactNode[] | React.ReactNode;
    onClose: () => void;
    open: boolean;
    color?: 'light' | 'dark';
    sx?: any;
};

export default function Dialog({ children, onClose, open, color = 'dark', sx }: DialogProps) {
    const theme = useTheme();
    const customizations = useCustomizations();
    return (
        <MuiDialog
            disablePortal={true}
            open={open}
            onClose={onClose}
            tabIndex={0}
            disableEnforceFocus={true}
        >
            <Box
                sx={{
                    position: 'fixed',
                    zIndex: zIndex.alwaysVisible,
                    backgroundColor:
                        color === 'dark'
                            ? theme.palette.secondary.dark
                            : theme.palette.common.white,
                    color:
                        color === 'dark' ? theme.palette.primary.light : theme.palette.text.primary,
                    border: '1px solid',
                    borderColor: customizations.primaryColor,
                    left: '50%',
                    top: '50%',
                    transform: `translateX(-50%) translateY(-50%)`,
                    borderRadius: `calc(${ensurePixelValue(theme.shape.borderRadius)} * 2)`,
                    overflow: 'hidden',
                    [theme.breakpoints.down('md')]: {
                        width: `calc(100% - ${theme.spacing(2)}) !important`,
                        boxSizing: 'border-box',
                    },
                    maxHeight: '95vh',
                    overflowY: 'auto',
                    maxWidth: '450px',
                    width: '100%',
                    height: 'fit-content',
                    padding: theme.spacing(2),
                    '& > *': {
                        width: '100%',
                        maxWidth: '100%',
                        margin: 0,
                    },
                    ...sx,
                }}
            >
                {children}
                <IconButton
                    onClick={onClose}
                    ariaLabel="Close dialog"
                    sx={{
                        position: 'absolute',
                        top: theme.spacing(1.5),
                        right: theme.spacing(1.5),
                        width: 'auto',
                        height: 'auto',
                        '& .MuiSvgIcon-root': {
                            color: theme.palette.primary.contrastText,
                            ...sx,
                        },
                    }}
                    Icon={CloseRounded}
                ></IconButton>
            </Box>
        </MuiDialog>
    );
}
