import CloseIcon from '@mui/icons-material/Close';
import { CircularProgress, SxProps } from '@mui/material';
import { useState } from 'react';
import { DialcodeValues } from '@repo/common-utils/countries';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import IconButton from 'src/components/common/timeslots-buttons/icon-button/IconButton';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import EmailInput from 'src/components/common/timeslots-fields/inputs/email-input/EmailInput';
import PhoneNumberInput from 'src/components/common/timeslots-fields/inputs/phone-number-input/PhoneNumberInput';
import TextInput from 'src/components/common/timeslots-fields/inputs/text-input/TextInput';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import { useLocale } from '@repo/i18n';
import { putUser } from '@repo/widget-utils/services/fetchers/membership';
import { custom, email, minLength, regex, safeParse, string } from 'valibot';
import { MembershipUser } from '@repo/types';

type PersonalInfoEditorProps = {
    user: MembershipUser;
    onUpdated: (user: MembershipUser) => void;
    onSave: () => void;
    onError: (param: boolean) => void;
    onBack: () => void;
    sx?: SxProps;
};

const FirstNameValidator = string([minLength(1, 'firstName')]);
const LastNameValidator = string([minLength(1, 'lastName')]);
const EmailValidator = string('email', [email('email')]);
const PhoneValidator = string('phone', [minLength(3, 'phone'), regex(/^\d+$/, 'phone')]);
const PhonePrefixValidator = string('phonePrefix', [
    custom((value) => DialcodeValues.includes(value), 'phonePrefix'),
]);

export default function PersonalInfoEditor({
    user,
    onUpdated,
    onSave,
    onError,
    onBack,
    sx,
}: PersonalInfoEditorProps) {
    const { t } = useLocale();
    const [firstName, setFirstName] = useState(user.firstName);
    const [surname, setSurname] = useState(user.lastName);
    const [email, setEmail] = useState(user.email);
    const [phone, setPhone] = useState(user.phoneNumber);
    const [phonePrefix, setPhonePrefix] = useState(user.phonePrefix);
    const [error, setError] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        phonePrefix: false,
    });
    const [spinner, setSpinner] = useState(false);
    async function handleSave() {
        setSpinner(true);

        const firstNameError = !safeParse(FirstNameValidator, firstName).success;
        const lastNameError = !safeParse(LastNameValidator, surname).success;
        const emailError = !safeParse(EmailValidator, email).success;
        const phoneError = !safeParse(PhoneValidator, phone).success;
        const phonePrefixError = !safeParse(PhonePrefixValidator, phonePrefix).success;

        setError({
            firstName: firstNameError,
            lastName: lastNameError,
            email: emailError,
            phone: phoneError,
            phonePrefix: phonePrefixError,
        });

        if (firstNameError || lastNameError || emailError || phoneError || phonePrefixError) {
            setSpinner(false);
            return;
        }

        try {
            const userUpdateRequest: MembershipUser = {
                ...user,
                firstName: firstName,
                lastName: surname,
                email: email,
                phonePrefix: phonePrefix,
                phoneNumber: phone,
            };

            const updatedUser = await putUser(userUpdateRequest);
            onUpdated(updatedUser);
        } catch (error) {
            onError(true);
        }
        setSpinner(false);
        onSave();
    }

    return (
        <Panel sx={{ ...sx }}>
            <Layout sx={{ position: 'relative' }} flexDirection="column">
                <IconButton
                    ariaLabel="close"
                    Icon={CloseIcon}
                    onClick={onBack}
                    sx={{ position: 'absolute', right: 0, top: 0 }}
                />
                <PanelTitle>{t.updateProfile}</PanelTitle>
                <TextInput
                    value={firstName}
                    onChange={(value) => setFirstName(value)}
                    label={t.firstName}
                    error={error.firstName}
                />
                <TextInput
                    value={surname}
                    onChange={(value) => setSurname(value)}
                    label={t.lastName}
                    error={error.lastName}
                />
                <EmailInput
                    value={email}
                    onChange={(value) => setEmail(value)}
                    label={t.email}
                    error={error.email}
                    disabled={true}
                />
                <PhoneNumberInput
                    value={phone}
                    onChange={(value) => setPhone(value)}
                    phonePrefix={phonePrefix}
                    onPhonePrefixChange={(value) => setPhonePrefix(value)}
                    label={t.phoneNumber}
                    errorPrefix={error.phonePrefix}
                    errorNumber={error.phone}
                />
                <Layout spacing="dense" justify="center">
                    {!spinner ? (
                        <Button size="large" onClick={() => handleSave()} text={t.save} />
                    ) : (
                        <CircularProgress />
                    )}
                </Layout>
            </Layout>
        </Panel>
    );
}
