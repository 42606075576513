import { Theme } from '@mui/material';
import { zIndex } from 'src/utils/widget/zIndex';

export const ensurePixelValue = (value: string | number | undefined) => {
    if (typeof value === 'number') return value + 'px';

    return value;
};

export const bookingWidgetCardStyles = (theme: Theme) => {
    return {
        position: 'fixed',
        width: '100%',
        maxWidth: '350px',
        backgroundColor: theme.palette.primary.main,
        zIndex: zIndex.alwaysVisible,
        right: theme.spacing(6),
        padding: 0,
        boxSizing: 'border-box',
        bottom: 0,
        borderRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        border: 'none',
    };
};

export const toggleButtonStyles = (theme: Theme) => {
    return {
        position: 'absolute',
        top: '-1em',
        padding: theme.spacing(1.5),
        marginTop: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.secondary.contrastText,
        '&:hover, &:active': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.grey[100],
        },
    };
};

export const numberInputStyles = (theme: Theme) => {
    return {
        position: 'absolute',
        padding: theme.spacing(1.5),
        marginTop: theme.spacing(1),
        zIndex: zIndex.alwaysVisible,
        backgroundColor: 'white',
        width: `calc(100%)`,
    };
};

export const calendarInputStyles = (theme: Theme) => {
    return {
        position: 'absolute',
        top: '-1em',
        padding: theme.spacing(1.5),
        marginTop: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        backgroundColor: theme.palette.secondary.main,
        '&:hover, &:active': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.primary.main,
        },
    };
};

export const calendarContainerStyles = (theme: Theme) => {
    return {
        padding: theme.spacing(2, 0),
        maxWidth: '340px',
        width: '340px',
        maxHeight: '425px',
        height: '425px',
        overflow: 'auto',
    };
};

export const timeslotsContainerStyles = (theme: Theme) => {
    return {
        maxHeight: '300px',
        overflow: 'auto',
        width: 'fit-content',
        margin: theme.spacing(3),
    };
};

export const getRootStyling = (
    theme: Theme,
    visible: boolean,
    boxRef: React.RefObject<HTMLElement>,
) => {
    const boundingClientRect = boxRef.current?.getBoundingClientRect?.();
    const top = boundingClientRect?.top ?? 0;
    const bottom = boundingClientRect?.bottom ?? 0;

    return visible
        ? {
              ...bookingWidgetCardStyles(theme),
              transition: 'ease .4s',
              transform: 'translateY(1%) translateZ(0)',
              boxSizing: 'border-box',
              [theme.breakpoints.down('sm')]: {
                  maxWidth: '100%',
                  width: '100%',
                  right: 0,
              },
          }
        : {
              ...bookingWidgetCardStyles(theme),
              transition: 'ease .4s',
              transform: `translateY(calc(100% - calc(${ensurePixelValue(
                  bottom - top,
              )}))) translateZ(0)`,
              boxSizing: 'border-box',
              [theme.breakpoints.down('sm')]: {
                  maxWidth: '100%',
                  width: '100%',
                  right: 0,
              },
          };
};

export const primaryColor = (theme: Theme) => {
    return { color: theme.palette.primary.main };
};

export const secondaryColorTextContrast = (theme: Theme) => {
    return { color: theme.palette.secondary.contrastText };
};
export const primaryColorTextContrast = (theme: Theme) => {
    return { color: theme.palette.primary.contrastText };
};

export const getToggleButtonStyling = (theme: Theme) => {
    return { ...toggleButtonStyles(theme) };
};

export const getNumberInputStyling = (theme: Theme) => {
    return { ...numberInputStyles(theme) };
};

export const getCalendarInputStyling = (theme: Theme) => {
    return { ...calendarInputStyles(theme) };
};
