import { Alert, Box, LinearProgress, SxProps, useTheme } from '@mui/material';
import { tzdate } from '@repo/tzdate';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import { MembershipUserValueCard, OrderStatusEnum } from '@repo/types';
import AmountChart from './AmountChart';
import { ValueCardQRCode } from './ValueCardQRCode';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import LinkButton from 'src/components/common/timeslots-buttons/link-button/LinkButton';

type CreditsInfoValueCardProps = {
    credits: MembershipUserValueCard;
    buttonSx?: SxProps;
};

export default function CreditsInfoValueCard({ credits, buttonSx }: CreditsInfoValueCardProps) {
    switch (credits.purchaseOrderStatus) {
        case OrderStatusEnum.Completed:
            return <OrderCompletedView credits={credits} buttonSx={buttonSx}></OrderCompletedView>;
        case OrderStatusEnum.AwaitingPayment:
            return <OrderAwaitingPaymentView></OrderAwaitingPaymentView>;
        default:
            return <></>;
    }
}

function OrderCompletedView({ credits, buttonSx }: CreditsInfoValueCardProps) {
    return <OrderCompletedInfo credits={credits} buttonSx={buttonSx}></OrderCompletedInfo>;
}

function OrderAwaitingPaymentView() {
    const { t } = useLocale();
    const { mutateValueCardsForUser, isLoadingValueCardsForUser } = useMemberContext();

    return (
        <>
            <Alert
                severity="info"
                color="info"
                action={
                    <LinkButton
                        onClick={() => mutateValueCardsForUser()}
                        text={`${t.update}`}
                        isLowercase
                        disabled={isLoadingValueCardsForUser}
                    />
                }
            >
                <span>{t.awaiting_payment_info}</span>
            </Alert>

            {isLoadingValueCardsForUser && <LinearProgress></LinearProgress>}
        </>
    );
}

function OrderCompletedInfo({
    credits,
    buttonSx,
}: {
    credits: MembershipUserValueCard;
    buttonSx?: SxProps;
}) {
    const valueCardDescription = credits.valueCardProduct.description;
    const { isRestrictedToPurchaseSite } = credits.valueCardProduct.configuration;

    return (
        <>
            <ValueCardDescriptionText description={valueCardDescription}></ValueCardDescriptionText>

            <Box style={{ textAlign: 'center' }}>
                <Box sx={{ height: '120px', display: 'flex', justifyContent: 'center' }}>
                    <CreditsLeftCircle
                        creditsLeft={credits.balance?.creditsAvailable}
                        creditLimit={credits.valueCardProduct.configuration.creditLimit}
                    ></CreditsLeftCircle>
                </Box>

                <CreditsLeftText></CreditsLeftText>

                <PurchaseDateInfo purchaseDate={credits.purchaseDate}></PurchaseDateInfo>

                {isRestrictedToPurchaseSite && (
                    <PurchaseSiteInfo purchaseSite={credits.purchaseSite}></PurchaseSiteInfo>
                )}
                <ValueCardQRCode
                    qrReference={credits.qrReference}
                    orderReference={credits.orderReference}
                    buttonSx={{ ...buttonSx }}
                />
            </Box>
        </>
    );
}

function ValueCardDescriptionText(props: { description: string }) {
    return <Text sx={{ textAlign: 'center' }}>{props.description}</Text>;
}

function CreditsLeftText() {
    const theme = useTheme();
    const { t } = useLocale();

    return (
        <Text variant="small" sx={{ mt: theme.spacing(2), textAlign: 'center' }}>
            {t.creditsLeft.toUpperCase()}
        </Text>
    );
}

function PurchaseDateInfo(props: { purchaseDate: string }) {
    const theme = useTheme();

    return (
        <Text variant="small" sx={{ mt: theme.spacing(2), textAlign: 'center' }}>
            {`Kjøpsdato: ${tzdate(props.purchaseDate, false).format('DD/MM/YYYY')}`}
        </Text>
    );
}

function PurchaseSiteInfo(props: { purchaseSite: string }) {
    return (
        <Text variant="small" sx={{ textAlign: 'center' }}>
            {`Kan brukes hos: ${props.purchaseSite}`}
        </Text>
    );
}

function CreditsLeftCircle(props: { creditsLeft?: number; creditLimit: number }) {
    const theme = useTheme();
    return (
        <>
            <AmountChart
                amount={props.creditsLeft ? props.creditsLeft / 100 : 0}
                height={120}
                width={120}
                max={props.creditLimit / 100}
            />
            <Text
                fontWeight="bold"
                sx={{
                    fontSize: '60px',
                    height: theme.spacing(10),
                    transform: `translateY(${theme.spacing(2)})`,
                }}
            >
                {(props.creditsLeft ?? 0) / 100}
            </Text>
        </>
    );
}
