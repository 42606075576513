import { ProductCard2 } from 'src/components/domain/product-card-2/ProductCard2';
import { ProductCardInfo } from 'src/components/domain/product-card-2/ProductCardInfo';
import { useLocale } from '@repo/i18n';
import { companyAtom } from 'src/state/company';
import { MediaQueryAttributeInput } from '@repo/common-utils/mediaQueryAttributeInputHelper';
import { useCustomizations } from 'src/components/utils/theme/customizations';
import ProductCardListContainer from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListContainer';
import ProductCardListItem from 'src/widgets/activities/product/product-list-2/product-card-list-2/ProductCardListItem';
import { useAtom } from 'ximple';
import { useMemberContext } from '../timeslots/MemberContext';

type ValueCardProductItemCardsProps = {
    productIds: number[];
    urls: string[];
    imageUrls: string[];
    numElements: MediaQueryAttributeInput;
    scroll: boolean;
};

export default function ValueCardProductItemCards({
    productIds,
    urls,
    imageUrls,
    numElements,
    scroll,
}: ValueCardProductItemCardsProps) {
    const [{ company }] = useAtom(companyAtom);

    const { loggedInUser, availableValueCardProductsForUser } = useMemberContext();

    const valueCardProducts =
        loggedInUser && availableValueCardProductsForUser.length > 0
            ? availableValueCardProductsForUser
            : company?.valueCardProducts;

    const { t } = useLocale();
    const customizations = useCustomizations();

    const getDisplayTitle = (name: string, configuration: any) => {
        const hasSubscriptionAndIsMonthly =
            configuration?.subscriptionConfiguration?.chargeInterval &&
            configuration.subscriptionConfiguration.chargeInterval.toLowerCase() === 'monthly';

        if (hasSubscriptionAndIsMonthly) {
            return name + ' ' + t.monthlyCharge.toLowerCase();
        }
        return name;
    };

    return (
        <ProductCardListContainer
            numElements={numElements}
            scroll={scroll}
            productListPadding={customizations.productListPadding}
        >
            {valueCardProducts &&
                valueCardProducts
                    .filter(({ id }) => productIds.some((productId) => productId === id))
                    .toSorted((a, b) => productIds.indexOf(a.id) - productIds.indexOf(b.id))
                    .map(
                        (
                            {
                                id,
                                name,
                                description,
                                price,
                                originalPrice,
                                campaign,
                                productType,
                                configuration,
                            },
                            _i,
                            arr,
                        ) => {
                            const indexOfValueCardProduct = productIds.indexOf(id);
                            return (
                                <ProductCardListItem
                                    key={id}
                                    numElements={numElements}
                                    scroll={scroll}
                                    numProductCatalogs={arr.length}
                                >
                                    <ProductCard2
                                        url={
                                            !urls.length
                                                ? productType.productPageUrl
                                                : urls[indexOfValueCardProduct]
                                        }
                                        image={{
                                            url: !imageUrls.length
                                                ? productType.cardImageUrl
                                                : imageUrls[indexOfValueCardProduct],
                                        }}
                                        category={productType.name}
                                        campaign={campaign?.name}
                                        body={
                                            <ProductCardInfo
                                                url={
                                                    !urls.length
                                                        ? productType.productPageUrl
                                                        : urls[indexOfValueCardProduct]
                                                }
                                                title={getDisplayTitle(name, configuration)}
                                                description={description}
                                                fromPrice={price / 100}
                                                originalPrice={originalPrice / 100}
                                                showFrom={false}
                                            />
                                        }
                                    />
                                </ProductCardListItem>
                            );
                        },
                    )}
        </ProductCardListContainer>
    );
}
