import { Box, CircularProgress } from '@mui/material';
import { route } from 'src/components/common/router/Router';
import { useEffect, useState } from 'react';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import ValueCardSummaryPanel from 'src/components/domain/timeslots-summary-panel/ValueCardSummaryPanel';
import { useLocale } from '@repo/i18n';
import { companyAtom } from 'src/state/company';
import { contactAtom } from 'src/state/contactInfo';
import { useAtomReducer } from 'src/hooks';
import { uiStateAtom } from 'src/state/ui-timeslots';
import { valueCardAtom, ValueCardPurchase } from 'src/state/valueCard';
import { valueCardCheckoutTabStateAtom } from 'src/state/valueCardCheckoutTab';
import { getTotalPriceWithReductions } from '@repo/widget-utils/value-cards/price';
import { useAtom } from 'ximple';
import Payment from '../../payment/Payment';
import { useConfigurations } from '@repo/widget-utils/widgetsConfiguration';
import { PaymentInfo } from '@repo/types';
import {
    calculateAppliedGiftcards,
    getAppliedPromoCodeIfApplicableForValueCardPurchase,
} from '@repo/widget-utils/price-helper';
import { dispatchWidgetEvent } from 'src/events/eventDispatcher';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';
import { useCartContext } from 'src/widgets/CartContext';

export default function PaymentView({
    setPaymentStatusDescription,
}: {
    setPaymentStatusDescription: (description: string) => void;
}) {
    const { t } = useLocale();
    const [valueCard, valueCardDispatch] = useAtomReducer(valueCardAtom);
    const { loggedInUser, availableValueCardProductsForUser } = useMemberContext();
    const hasNothingToPay =
        !!valueCard.reservation?.orderReference && !valueCard.reservation?.paymentId;
    const [paymentSuccessful, setPaymentSuccessful] = useState<boolean>(hasNothingToPay);
    const [company] = useAtom(companyAtom);
    const config = useConfigurations();
    const [, contactInfoDispatch] = useAtomReducer(contactAtom);
    const [, dispatch] = useAtomReducer(uiStateAtom);

    const { category, membership, credits } = valueCard;

    const valueCardProducts =
        loggedInUser && availableValueCardProductsForUser.length > 0
            ? availableValueCardProductsForUser
            : company.company?.valueCardProducts;

    const currentId = category === 'membership' ? membership.id : credits.id;
    const currentValueCard = valueCardProducts?.find(({ id }) => id === currentId);

    const { appliedPromoCode, giftcards } = useCartContext();
    const applicableAndAppliedPromoCode = getAppliedPromoCodeIfApplicableForValueCardPurchase(
        appliedPromoCode,
        currentId,
    );

    const discounts = applicableAndAppliedPromoCode ? [applicableAndAppliedPromoCode.discount] : [];

    const totalPriceWithReductions = getTotalPriceWithReductions(currentValueCard, discounts);

    const [, valueCardTabDispatch] = useAtomReducer(valueCardCheckoutTabStateAtom);

    const handlePaymentCompleted = () => {
        setPaymentSuccessful(true);

        const appliedGiftcards = calculateAppliedGiftcards(giftcards, totalPriceWithReductions);
        dispatchWidgetEvent({
            eventType: 'book',
            purchaseProduct: valueCardAtom.subject.value,
            reference: valueCardAtom.subject.value.reservation?.orderReference,
            promoCode: applicableAndAppliedPromoCode?.promoCode,
            giftCards: appliedGiftcards.map((appliedGiftcard) => ({
                giftcardReference: appliedGiftcard.giftcardStatus.id,
                priceReduction: appliedGiftcard.priceReduction,
            })),
        });
    };

    const handlePaymentCancelled = () => {
        valueCardTabDispatch({ type: 'GOTO_VALUE_CARD_CONTACT_INFO' });
    };

    const handleGotoMyProfile = () => {
        contactInfoDispatch({ type: 'RESET' });
        valueCardDispatch({ type: 'RESET' });
        dispatch({ type: 'INITIALIZE' });
        if (loggedInUser !== null) {
            route('/my-profile');
        } else {
            route('/');
        }
    };

    const paymentInfo =
        valueCard.reservation && company.currentSite
            ? {
                  payment_id: valueCard.reservation.paymentId,
                  checkout_key: company.currentSite.netsCheckoutKey,
                  payment_gateway: config.paymentProvider!,
                  reference: valueCard.reservation.orderReference,
              }
            : undefined;

    useEffect((): void => {
        if (valueCard.reservation?.orderReference && paymentSuccessful) {
            setPaymentStatusDescription(t.successful);
        } else if (valueCard.reservation && !valueCard.reservation?.orderReference) {
            setPaymentStatusDescription(t.failed);
        } else {
            setPaymentStatusDescription(t.valueCard);
        }
    }, [
        paymentSuccessful,
        valueCard,
        setPaymentStatusDescription,
        t.failed,
        t.successful,
        t.valueCard,
    ]);

    return (
        <Box
            sx={{
                width: '1280px',
                maxWidth: '100%',
                margin: '0 auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Layout
                flexDirection="column"
                spacing="wide"
                sx={{ maxWidth: '100%', width: '100%' }}
                mobileSx={{ width: '100%', boxSizing: 'border-box' }}
            >
                <Layout mobileFlexDirection="column">
                    <PaymentPanel
                        isAuthenticated={loggedInUser !== null}
                        paymentSuccessful={paymentSuccessful}
                        paymentInfo={paymentInfo}
                        valueCard={valueCard}
                        handlePaymentCompleted={handlePaymentCompleted}
                        handlePaymentCancelled={handlePaymentCancelled}
                        totalPriceWithReductions={totalPriceWithReductions}
                    />
                    <Layout flexDirection="column" mobileFlexDirection="column">
                        <ValueCardSummaryPanel valueCardState={valueCard} isOnPaymentView />
                        <Layout justify="flex-end">
                            <Button
                                disabled={!paymentSuccessful && totalPriceWithReductions > 0}
                                text={loggedInUser !== null ? t.goToMyPage : t.returnToWebsite}
                                onClick={handleGotoMyProfile}
                            />
                        </Layout>
                    </Layout>
                </Layout>
            </Layout>
        </Box>
    );
}

function PaymentPanel(props: {
    isAuthenticated: boolean;
    paymentSuccessful: boolean;
    paymentInfo?: PaymentInfo;
    valueCard: ValueCardPurchase;
    handlePaymentCompleted: () => void;
    handlePaymentCancelled: () => void;
    totalPriceWithReductions: number;
}) {
    const {
        isAuthenticated,
        paymentSuccessful,
        totalPriceWithReductions,
        paymentInfo,
        handlePaymentCompleted,
        handlePaymentCancelled,
        valueCard,
    } = props;

    const { t } = useLocale();

    const showPaymentPanel = isAuthenticated && !!paymentInfo;

    if (!showPaymentPanel)
        return (
            <Box
                sx={{
                    width: '550px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <CircularProgress color="primary" />
            </Box>
        );

    return (
        <Box
            sx={(theme) => ({
                width: '550px',
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                },
                '#nets-checkout-iframe': {
                    width: '100%',
                },
            })}
        >
            {paymentSuccessful || (totalPriceWithReductions <= 0 && !paymentInfo) ? (
                <Layout flexDirection="column">
                    <PanelTitle variant="h3">{t.thankYouForShoppingWithUs}</PanelTitle>
                    <Layout flexDirection="column" spacing="dense">
                        <Text>
                            {t.yourOrderReferenceIs}: <b>{valueCard.reservation?.orderReference}</b>
                        </Text>
                        <Text variant="small">
                            {
                                t.aConfirmationWillBeSentToYourEmailAddressAsSoonAsWeHaveProcessedYourOrder
                            }
                        </Text>
                    </Layout>
                </Layout>
            ) : (
                (window as any).Dibs && (
                    <Payment
                        paymentInfo={paymentInfo!}
                        onPaymentCompleted={handlePaymentCompleted}
                        onPaymentCancelled={handlePaymentCancelled}
                    />
                )
            )}
        </Box>
    );
}
