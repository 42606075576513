import { CircularProgress } from '@mui/material';
import { TZDate, tzdate } from '@repo/tzdate';
import { Fragment } from 'react';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import PanelTitle from 'src/components/common/timeslots-typography/PanelTitle';
import Text from 'src/components/common/timeslots-typography/Text';
import { useLocale } from '@repo/i18n';
import { companyAtom } from 'src/state/company';
import { MembershipSite } from '@repo/types';
import { useAtom } from 'ximple';
import BookingPanel from '../booking-panel/BookingPanel';
import { useMemberContext } from 'src/widgets/timeslots/timeslots/MemberContext';

export default function UpcomingBookings() {
    const { t } = useLocale();
    const [company] = useAtom(companyAtom);
    const {
        bookingsForUser: bookings,
        mutateBookingsForUser,
        isLoadingBookingsForUser: isBookingsLoading,
    } = useMemberContext();

    function getSiteName(siteKey: string) {
        const site = company.sites?.find(
            (site: MembershipSite) => siteKey === site.key && site.name,
        );
        return site?.name;
    }

    const handleBookingCancelled = (orderReference: string) => {
        const newBookings = bookings.filter((x) => x.orderReference !== orderReference) ?? [];
        mutateBookingsForUser(newBookings);
    };

    return (
        <Fragment>
            <Layout flexDirection="column">
                <PanelTitle variant="h2">{t.upcomingBookings}</PanelTitle>

                <Layout flexDirection="column" spacing="small">
                    {!isBookingsLoading &&
                        bookings &&
                        bookings
                            .filter((x) => tzdate(x.endAt, false).isAfter(TZDate.now()))
                            .sort((x, y) =>
                                tzdate(x.startAt, false).isAfter(tzdate(y.startAt, false)) ? 1 : -1,
                            )
                            .map(
                                ({
                                    bilberryReference,
                                    startAt,
                                    endAt,
                                    numberOfTickets,
                                    siteKey,
                                    status,
                                    orderReference,
                                    qrReference,
                                    cancellationDeadlineAt,
                                }) => (
                                    <BookingPanel
                                        key={`booking-${bilberryReference}`}
                                        date={tzdate(startAt, false)}
                                        startTime={tzdate(startAt, false)}
                                        endTime={tzdate(endAt, false)}
                                        participantCount={numberOfTickets}
                                        location={
                                            company.company?.name + ' ' + getSiteName(siteKey)
                                        }
                                        orderReference={orderReference}
                                        qrReference={qrReference}
                                        isProcessing={status.toLowerCase() === 'created'}
                                        hideCancel={tzdate(cancellationDeadlineAt, false).isBefore(
                                            Date.now(),
                                        )}
                                        onCancelled={handleBookingCancelled}
                                    />
                                ),
                            )}
                    {!isBookingsLoading && bookings && bookings.length === 0 && (
                        <Layout flexDirection="column" spacing="dense">
                            <Text>{t.youDontHaveAnyUpcomingBookings}</Text>
                            <Text variant="small">
                                {t.youCanPurchaseBookingsFromTheShopOnThisWebsite}
                            </Text>
                        </Layout>
                    )}
                </Layout>
            </Layout>
            {isBookingsLoading && <CircularProgress color="primary" />}
        </Fragment>
    );
}
