import { localeAtom } from '@repo/i18n';
import { MembershipValueCardProduct } from '@repo/types';

export function creditsGroups(credits: MembershipValueCardProduct[]) {
    return credits.reduce(
        (acc, product) => {
            const key =
                '' + (product.valueCardProductCategory?.name ?? localeAtom.subject.value.t.unknown);
            return {
                ...acc,
                [key]: [...(acc[key] ?? []), product.id],
            };
        },
        {} as Record<string, number[]>,
    );
}

export function valueCardGroups(valueCards: MembershipValueCardProduct[]) {
    return valueCards.reduce(
        (acc, product) =>
            product.configuration.creditLimit
                ? {
                      ...acc,
                      credits: [...acc.credits, product],
                  }
                : {
                      ...acc,
                      memberships: [...acc.memberships, product],
                  },
        {
            memberships: [] as MembershipValueCardProduct[],
            credits: [] as MembershipValueCardProduct[],
        },
    );
}

export function valueCardsByType(valueCards: MembershipValueCardProduct[], typeId: number) {
    return valueCards.filter((valueCard) => valueCard.productTypeId === typeId);
}
