import { createRoot } from 'react-dom/client';
import { useEffect } from 'react';
import { useLocale } from '@repo/i18n';
import { uiStateAtom } from 'src/state/ui-timeslots';
import { isValueCardPurchaseValid } from 'src/state/valueCard';
import { showError } from 'src/utils/widget/error-handling';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import Profile from './Profile';
import ValueCardCheckout from './ValueCardCheckout';
import { HashHistory, Update } from 'history';
import { Route, Router } from 'src/components/common/router/Router';
import { useAuthenticationContext } from './AuthenticationContext';

export function BilberryTimeslotsWidget(): JSX.Element {
    const { isAuthenticated } = useAuthenticationContext();

    const { t } = useLocale();

    // Remove the hash route, if the user was on such a route
    useEffect(() => {
        const navigationEntries = performance.getEntriesByType('navigation');
        const navigationEntry =
            navigationEntries.length > 0
                ? (navigationEntries[0] as PerformanceNavigationTiming)
                : undefined;

        if (navigationEntry?.type === 'reload') {
            window.location.hash = '';
        }
    }, []);

    const handleRoute = async (e: Update, history: HashHistory) => {
        switch (e.location.hash) {
            case '/my-profile':
                if (!isAuthenticated) {
                    showError(t.couldntOpenProfilePleaseSignInFirst);
                    history.push('/');
                }
                break;
            case '/value-card':
                if (
                    uiStateAtom.subject.value.currentUserFlow !== 'profile' &&
                    !isValueCardPurchaseValid()
                ) {
                    showError(t.couldntOpenValueCardCheckoutNotAllValueCardInformationHasBeenSet);
                    history.push('/');
                }
                break;
        }
    };

    return (
        <Router onChange={handleRoute}>
            <Route path="/my-profile">
                <Profile />
            </Route>
            <Route path="/value-card">
                <ValueCardCheckout />
            </Route>
        </Router>
    );
}

class BilberryTimeslots extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        // finally we need to wrap our application in a StylesProvider
        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <BilberryTimeslotsWidget />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement(): void {
    customElements.define('bilberry-timeslots', BilberryTimeslots);
}
