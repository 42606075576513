import { useEffect, useState } from 'react';
import CheckoutTabs from 'src/components/domain/checkout/CheckoutTabs';
import ContactView from 'src/components/domain/timeslots-views/value-card-checkout/ContactView';
import PaymentView from 'src/components/domain/timeslots-views/value-card-checkout/PaymentView';
import ValueCardSelectionView from 'src/components/domain/timeslots-views/value-card-checkout/ValueCardSelectionView';
import {
    initializeHiddenBodyStyle,
    useHiddenBody as useHiddenBody,
} from 'src/hooks/common/ui/useHiddenBody';
import { useLocale } from '@repo/i18n';
import { capitalize } from '@repo/common-utils/TextUtils';
import { useAtomReducer } from 'src/hooks';
import { uiStateAtom } from 'src/state/ui-timeslots';
import {
    ValueCardCheckoutTabIndex,
    valueCardCheckoutTabStateAtom,
} from 'src/state/valueCardCheckoutTab';

const hiddenBodyStyle = initializeHiddenBodyStyle('timeslots');

export default function ValueCardCheckout() {
    const { t } = useLocale();

    useEffect(() => {
        uiStateAtom.update({ type: 'SET_CURRENT_USERFLOW', value: 'product' });
    }, []);
    useHiddenBody(hiddenBodyStyle);

    const [{ valueCardCheckout }, valueCardTabDispatch] = useAtomReducer(
        valueCardCheckoutTabStateAtom,
    );
    const [paymentStatusDescription, setPaymentStatusDescription] = useState<string>('');

    const isOnPaymentTab = valueCardCheckout.tabIndex === ValueCardCheckoutTabIndex.Payment;
    const checkoutTabset = {
        ordered: true,
        tabs: [
            {
                name: t.selectProduct,
                title: capitalize(t.selectProduct),
                content: <ValueCardSelectionView />,
                disabled: isOnPaymentTab,
            },
            {
                name: t.contact,
                title: capitalize(t.contact),
                description: capitalize(t.pleaseRegister),
                content: <ContactView />,
                disabled: isOnPaymentTab,
            },
            {
                name: t.payment,
                title: capitalize(t.payment),
                description: paymentStatusDescription,
                content: <PaymentView setPaymentStatusDescription={setPaymentStatusDescription} />,
                disabled: !isOnPaymentTab,
            },
        ],
    };

    return (
        <CheckoutTabs
            tabset={checkoutTabset}
            currentTab={valueCardCheckout.tabIndex}
            onChange={(index) =>
                valueCardTabDispatch({ type: 'GOTO_VALUE_CARD_TAB_BY_INDEX', value: index })
            }
        />
    );
}
