import { Alert, capitalize, LinearProgress, SxProps, useTheme } from '@mui/material';
import { TZDate } from '@repo/tzdate';
import { useState } from 'react';
import Button from 'src/components/common/timeslots-buttons/button/Button';
import LinkButton from 'src/components/common/timeslots-buttons/link-button/LinkButton';
import Layout from 'src/components/common/timeslots-containers/layouts/layout/Layout';
import Panel from 'src/components/common/timeslots-containers/panel/Panel';
import Text from 'src/components/common/timeslots-typography/Text';
import QrViewer from 'src/components/domain/timeslots-qr-viewer/QrViewer';
import { useLocale } from '@repo/i18n';
import { BUILD_VERSION } from '@repo/widget-env/__autogen/env';
import { cancelReservation } from '@repo/widget-utils/services/fetchers/membership';

const baseUrl =
    (BUILD_VERSION as any) === 'production'
        ? 'https://bilberry-timeslots-onsite.bilberry.no/'
        : 'https://bilberry-timeslots-onsite-test.bilberry.no/';

type BookingPanelProps = {
    date: TZDate;
    startTime: TZDate;
    endTime: TZDate;
    participantCount: number;
    location: string;
    orderReference: string;
    qrReference: string;
    isProcessing?: boolean;
    hideCancel: boolean;
    buttonSx?: SxProps;
    onCancelled: (orderReference: string) => void;
};

export default function BookingPanel({
    date,
    startTime,
    endTime,
    participantCount,
    location,
    orderReference,
    qrReference,
    isProcessing = false,
    hideCancel,
    buttonSx,
    onCancelled,
}: BookingPanelProps) {
    const { t } = useLocale();
    const theme = useTheme();

    const [isCancelling, setIsCancelling] = useState(false);
    const [apiErrorMessage, setApiErrorMessage] = useState<string | null>(null);
    const [areYouSure, setAreYouSure] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false);

    const url = `${baseUrl}/?orderReference=${
        qrReference && qrReference !== '' ? qrReference : orderReference
    }`;

    const handleCancel = async () => {
        setIsCancelling(true);
        setApiErrorMessage(null);
        try {
            await cancelReservation(orderReference);
            onCancelled(orderReference);
            setAreYouSure(false);
        } catch (e) {
            if (e instanceof Error) {
                setApiErrorMessage(e.message);
            } else {
                throw e;
            }
        } finally {
            setIsCancelling(false);
        }
    };

    return (
        <Panel
            variant="shaded"
            sx={{
                animation: `enter 1s linear`,
                minHeight: '110px',
                boxSizing: 'border-box',
                opacity: isProcessing ? 0.5 : 1,
                [theme.breakpoints.down('md')]: {
                    maxHeight: '160px',
                },
            }}
        >
            {!areYouSure && (
                <Layout
                    flexDirection="column"
                    spacing="dense"
                    tabbable
                    onClick={() => setShowQrCode(true)}
                >
                    <Layout justify="space-between">
                        <Layout spacing="small">
                            <Text sx={{ fontSize: theme.typography.h5.fontSize }} fontWeight="bold">
                                {date.format('ll')},
                            </Text>
                            <Text sx={{ fontSize: theme.typography.h5.fontSize }}>
                                {startTime.format('LT')} - {endTime.format('LT')}
                            </Text>
                        </Layout>
                        {isProcessing && <Text variant="small">{t.processing}...</Text>}
                    </Layout>
                    <Text variant="large">
                        {capitalize(t.numberOfPersons.parsed(participantCount))} - {location}
                    </Text>
                    <Layout justify="space-between">
                        <Text variant="small">
                            {t.orderReference + ': '} {orderReference}
                        </Text>
                        {!isProcessing && !hideCancel && (
                            <LinkButton
                                isLowercase
                                text={t.cancel}
                                disabled={startTime.isBefore(Date.now())}
                                onClick={(e?: React.MouseEvent) => {
                                    if (!e) return;
                                    e.stopPropagation();
                                    setAreYouSure(true);
                                }}
                            />
                        )}
                    </Layout>
                </Layout>
            )}
            {areYouSure && (
                <Layout
                    flexDirection="column"
                    alignItems="center"
                    justify="space-between"
                    spacing="normal"
                    sx={{ height: '100%' }}
                >
                    <Text>{t.areYouSureYouWantToCancelYourBooking}</Text>
                    <Layout>
                        <Button
                            text={t.yes}
                            onClick={handleCancel}
                            sx={{ ...buttonSx }}
                            disabled={isCancelling}
                        />
                        <Button
                            text={t.no}
                            onClick={() => setAreYouSure(false)}
                            sx={{ ...buttonSx }}
                            disabled={isCancelling}
                        />
                    </Layout>
                    {isCancelling && <LinearProgress />}
                    {apiErrorMessage && <Alert severity="error">{apiErrorMessage}</Alert>}
                </Layout>
            )}

            {!areYouSure && setShowQrCode && !isProcessing && (
                <QrViewer
                    open={showQrCode}
                    setOpen={setShowQrCode}
                    url={url}
                    orderReference={qrReference !== '' ? qrReference : orderReference}
                />
            )}
        </Panel>
    );
}
