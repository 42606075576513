import { createRoot } from 'react-dom/client';
import { getStringAttribute } from '@repo/widget-utils/attributes/attribute-helper';
import BilberryCustomElement from 'src/widgets/BilberryCustomElement';
import { WidgetWrapper } from 'src/widgets/WidgetWrapper';
import ToggleTimeslotButton from './ToggleTimeslotButton';

class BookTimeslotButton extends BilberryCustomElement {
    constructor() {
        super();
    }

    connectedCallback() {
        super.connectedCallback();

        const text = getStringAttribute(this, 'buy-or-book', true) ?? '';
        const size = this.getAttribute('size')?.toLowerCase();
        const xsSize = this.getAttribute('xs-size')?.toLowerCase();

        let sizeOrDefault: 's' | 'm' | 'l' = 's';
        let xsSizeOrDefault: 's' | 'm' | 'l' = 's';
        if (size === 's' || size === 'm' || size === 'l') {
            sizeOrDefault = size;
        }

        if (xsSize === 's' || xsSize === 'm' || xsSize === 'l') {
            xsSizeOrDefault = xsSize;
        }

        // finally we need to wrap our application in a StylesProvider
        createRoot(this._mountPoint).render(
            <WidgetWrapper
                mountPoint={this._mountPoint}
                popoverMountPoint={this._popoverMountPoint}
                shadowRoot={this._shadowRoot}
            >
                <ToggleTimeslotButton text={text} size={sizeOrDefault} xsSize={xsSizeOrDefault} />
            </WidgetWrapper>,
        );
    }
}

export default function defineCustomElement() {
    customElements.define('bilberry-book-timeslot-button', BookTimeslotButton);
}
